@import '../../common/variables';

@media only screen and (min-width: $widthMobile) {
  .section-graph {
    height: calc(100vh + 14.7222vh);
    display: flex;
    flex-direction: column;
    background-color: #f7f7fa;
    align-items: center;
    justify-content: center;
  }
}

.section-graph-container {
  position: relative;
}

@media only screen and (min-width: $widthMobile) {
  .section-graph-container {
    position: relative;
    margin-top: 2vh;
    margin-left: 21.666666666666668vw;
    margin-right: 21.666666666666668vw;
  }
}

.section-graph-titre {
  /* UI Properties */
  text-align: left;
  font: normal normal normal 28px/40px Aileron-Regular;
  color: #282e49;

  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  border-bottom: 1px solid black;
  height: 100px;
}

@media only screen and (min-width: $widthMobile) {
  .section-graph-titre {
    text-align: left;
    font: normal normal normal 2.1875vw/5.092592592592593vh Aileron-Regular;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-left: unset;
    margin-right: unset;
    margin-top: unset;
  }
}

.divider-graph-titre {
  position: relative;
  margin-top: 3vh;
  margin-bottom: 3vh;
  width: 20.729166666666668vw;
  border-bottom: 1px solid #282e49;
}

.container-sides-expertise-graph {
  display: unset;
}

@media only screen and (min-width: $widthMobile) {
  .container-sides-expertise-graph {
    display: unset;
  }
}

.side-expertise {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .side-expertise {
    display: flex;
    flex-direction: column;
    margin-right: 2.8645833333333335vw;
    align-items: unset;
  }
}

@media only screen and (min-width: $widthMobile) {
  .side-graph {
    text-align: center;
  }
}

@keyframes play-once {
  to { background-position: 0 0; }
}


.side-expertise-head-text-container {
  text-align: left;
  display: flex;
}

@media only screen and (min-width: $widthMobile) {
  .side-expertise-head-text-container {
    width: 23.59375vw;
    text-align: left;
    display: flex;
  }
}

.side-expertise-head-text {
  width: 20.364583333333336vw;
  /* UI Properties */
  text-align: left;
  font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
  color: #282e49;
  opacity: 1;
  margin-bottom: 5vh;
}

.expertise-elements-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 275px;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .expertise-elements-container {
    flex-direction: row;
    justify-content: space-between;
    width: 56.66666666666667vw;
    margin-top: unset;
  }
}

.expertise-element {
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  border-bottom: 1px solid black;
  height: 130px;
}

@media only screen and (min-width: $widthMobile) {
  .expertise-element {
    width: 15vw;
    flex-direction: row;
    border-bottom: unset;
    height: unset;
    margin-bottom: unset;
  }
}

.no-border {
  border-right: unset;
  border-bottom: unset;
}

.gamme-icone-photo {
  max-width: 50px;
  max-height: 50px;
}

@media only screen and (min-width: $widthMobile) {
  .gamme-icone-photo {
    max-width: 3.229166666666667vw;
    max-height: 5.7407407407407405vh;
  }
}

.divider-expertise-element {
  height: 150px;
  border-right: 1px solid black;
}

.text-expertise-element {
  text-align: center;
  font: normal normal normal 19px/24px Aileron-Regular;
  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .text-expertise-element {
    text-align: center;
    font: normal normal normal 0.9375vw/2.222222222222222vh Aileron-Regular;
    color: #282e49;
  }
}

.logo-expertise-element {
  height: 50px;
}

@media only screen and (min-width: $widthMobile) {
  .logo-expertise-element {
    height: 80px;
  }
}

.expertise-element-group {
  display: flex;
}

.graph-photo {
  max-width: 350px;
}

@media only screen and (min-width: $widthMobile) {
  .graph-photo {
    max-width: 56.66666666666667vw;
    animation-iteration-count: 1;
    animation-play-state: paused;
  }
}

.expertise-element-flex {
  display: flex;
  margin-top: 2vh;
  margin-bottom: 10px;
  height: 90px;
}

@media only screen and (min-width: $widthMobile) {
  .expertise-element-flex {
    display: flex;
    margin-top: unset;
  }
}

.text-expertise-element-flex {
  margin-top: 10px;
  margin-left: 5px;
}

@media only screen and (min-width: $widthMobile) {
  .text-expertise-element-flex {
    margin-top: unset;
    line-height: 50px;
    margin-left: 5px;
  }
}
