@import '../../../common/variables';

.section-texte {
  height: unset;
  display: flex;
  flex-direction: column;
  background-color: #282e49;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-texte {
    height: calc(100vh + 14.7222vh);
  }
}

.section-texte-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
}

@media only screen and (min-width: $widthMobile) {
  .section-texte-container {
    width: 70%;
    margin-top: unset;
    margin-bottom: unset;
    flex-direction: row;
  }
}

.titre-doree {
  font: normal normal 300 25px Aileron-Regular;
  color: #c1a367;
  margin-bottom: 10px;
}

@media only screen and (min-width: $widthMobile) {
  .titre-doree {
    font: normal normal 300 20px Aileron-Regular; /* Adjust font size for mobile */
  }
}

.text-blanc, .text-gris, .en-savoir {
  font: normal normal 300 20px Aileron-Regular;
  color: #FFFFFF;
  margin-bottom: 10px;
  text-align: justify;
}

@media only screen and (min-width: $widthMobile) {
  .text-blanc, .text-gris, .en-savoir {
    font: normal normal 300 16px Aileron-Regular; /* Adjust font size for mobile */
  }
}

.en-savoir {
  text-decoration: underline;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  width: 100%;
}

.images-column {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .images-column {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .images-column {
    display: none;
  }

  .accordions-column {
    width: 100%;
  }
}

.accordions-column {
  width: 100%;
  padding: 20px;
}

@media only screen and (min-width: $widthMobile) {
  .accordions-column {
    width: 50%;
    margin-bottom: 50px; /* Pour l'espace en bas */
  }
}

.accordion {
  margin: 0 auto;
  width: 100%;
}

.accordion-item {
  border-bottom: 1px solid #fff;
  padding: 10px 0;
  cursor: pointer;
}

.accordion-title {
  width: 100%;
  padding: 8px;
  font-family: 'Aileron-Regular', sans-serif;
}

.accordion-title h2 {
  width: 100%;
  margin: 0;
  color: #fff;
  font: normal normal 300 25px Aileron-Regular; /* Taille de police fixe pour desktop */
  text-transform: uppercase;
}

@media only screen and (min-width: $widthMobile) {
  .accordion-title h2 {
    font-size: 25px; /* Maintenir la même taille de police pour mobile */
  }
}

.accordion-item.open .accordion-title h2 {
  color: #c1a367;
}

.accordion-content {
  width: 100%;
  padding: 15px;
  color: #fff;
  display: none;
  font-size: 18px;
  line-height: 24px;
}

.accordion-item.open .accordion-content {
  display: block;
}

/* Ajoutez cette classe pour définir la taille des images */
.equal-size-image {
  width: 600px;
  height: 930px;
  object-fit: cover; /* Garantit que l'image couvre toujours la taille définie */
}

.images-column img {
  /* Supprimez la définition de largeur et de hauteur */
  max-height: 400px; /* Vous pouvez garder cette ligne si vous souhaitez limiter la hauteur */
}

.specificite {
  color: grey; /* Couleur grise pour la spécificité */
  font-size: 18px; /* Taille de police pour la spécificité */
  margin-top: 10px; /* Marge supérieure pour espacer du texte principal */
}

.en-savoir-plus {
  border: 4px solid rgb(40, 46, 73);
  border-radius: 100px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
  background-color: #fff;
  color: rgb(40, 46, 73);
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 10px;
}

.en-savoir-plus:hover {
  background-color: #c1a367;
}

.image1-margin-top {
  margin-top: 40px; /* ou la valeur de marge souhaitée */
}
