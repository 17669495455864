@import '../common/variables';

.section-mentions-legales {
  width: 100vw;
  background: #f7f7fa 0% 0%;
}

.section-mentions-legales-container-bis {
  position: relative;
  margin-bottom: 6.481481481481481vh;
  margin: 0 30px 0 30px;
  padding-bottom: 50px;
}

@media only screen and (min-width: $widthMobile) {
  .section-mentions-legales-container-bis {
    margin: 0 10vw 0 10vw;
  }
}

.mentions-legales-title {
  /* UI Properties */
  font: normal normal normal 30px Aileron-Regular;
  color: #282e49;
  padding-top: 50px;
  text-align: left;
}

@media only screen and (min-width: $widthMobile) {
  .mentions-legales-title {
    /* UI Properties */
    font: normal normal normal 48px Aileron-Regular;
    text-align: center;
  }
}
.mentions-legales-sub-title {
  /* UI Properties */
  text-align: left;
  font: normal normal normal 26px Aileron-Regular;
  color: #282e49;
  text-decoration: underline;
}

.mentions-legales-paragraphe {
  /* UI Properties */
  text-align: justify;
  font: normal normal normal 16px Aileron-Regular;
  color: #282e49;
}