@import '../../common/variables';

.section-titre-1 {
  width: 100vw;
  margin: 0 auto;
  height: 241px;
  background-color: #282e49;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-1 {
    height: calc(50vh + 14.72vh);
  }
}

.section-titre-1-text {
  position: absolute;
  margin-top: 61px;
  margin-left: 30px;
  height: 13.61111111111111vh;
  text-align: left;
  font: normal normal normal 42px/49px Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-1-text {
    position: absolute;
    margin-top: 35vh;
    margin-left: 21.666666666666668vw;
    width: 35.10416666666667vw;
    height: 13.61111111111111vh;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #ffffff;
  }
}

.section-titre-2 {
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  height: 40vh;
  background-color: #ffffff;
  display: flex;
}

.section-titre-2-text {
  position: relative;
  margin-top: 13.333333333333332vh;
  margin-left: 21.666666666666668vw;
  width: 50vw;
  height: 13.61111111111111vh;
  text-align: left;
  font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  color: #282e49;
}

.div-lottie {
  display: flex;
  flex-direction: column;
  transform: translate(0, -25.5vh);
}
