@import '../../../components/common/variables';

.section-5-informations-legales {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.section-informations-legales-container-background {
  background-color: #f7f7fa;
}

@media only screen and (min-width: $widthMobile) {
  .section-informations-legales-container-background {
    height: calc(100vh + 14.7222vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.section-informations-legales-container {
  margin-top: 37px;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-informations-legales-container {
    margin-top: unset;
    margin-left: 21.666666666666668vw;
    margin-right: 14.583333333333334vw;
  }
}

.section-titre-informations-legales {
  text-align: left;
  font: normal normal normal 32px/35px Aileron-Regular;

  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-informations-legales {
    width: 45.677083333333336vw;
    height: 7.592592592592592vh;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  }
}

.informations-legales-titre-divider {
  width: 229px;
  border-bottom: 1px solid #282e49;
  margin-top: 13px;
  margin-bottom: 49px;
}

@media only screen and (min-width: $widthMobile) {
  .informations-legales-titre-divider {
    width: 20.729166666666668vw;
    border-bottom: 1px solid #282e49;
    margin-top: 4.0740740740740735vh;
    margin-bottom: 4.0740740740740735vh;
  }
}

.section-informations-legales-text {
  text-align: justify;
  font: normal normal 300 17px/20px Aileron-Regular;
  color: #282e49;
  margin-bottom: 70px;
  margin-right: 30px;
  line-height: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-informations-legales-text {
    width: 56.66666666666667vw;
    height: 34.72222222222222vh;
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    margin-top: 6.5740740740740735vh;
    margin-bottom: 11.388888888888888vh;
    margin-right: 0;
    line-height: 30px;
  }
}
