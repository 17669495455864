@import '../../components/common/variables';

.performances-page-afg-monetaire {
  overflow-x: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .performances-page-afg-monetaire {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
  }
}

.transition-div-bis {
  position: relative;
  top: calc((50vh + 14.72vh) / 2);
}

.transition-div {
  position: relative;
  top: 50vh;
}
