@import '../../components/common/variables';

@media only screen and (min-width: $widthMobile) {
  .performances-page {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
  }
}

.counter-app-bar {
  height: 14.7222222vh;
}


.transition-div {
  position: relative;
  top: 50vh;
}

