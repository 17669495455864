@import '../variables';

.section-footer {
  background: #e4e5ea 0% 0% no-repeat padding-box;
  background-color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .section-footer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.section-footer-container {
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

@media only screen and (min-width: $widthMobile) {
  .section-footer-container {
    padding-top: unset;
    padding-left: unset;
    padding-right: unset;
    position: relative;
    margin-bottom: 50px;
  }
}

.footer-section-1 {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

@media only screen and (min-width: $widthMobile) {
  .footer-section-1 {
    display: flex;
    flex-direction: row;
    width: 76.71875vw;
    justify-content: space-between;
  }
}

.fit-picture-footer {
  max-width: 17.395833333333336vw;
  max-height: 6.481481481481481vh;
  min-width: 197px;
  min-height: 42px;
  margin-top: 109px;
}

@media only screen and (min-width: $widthMobile) {
  .fit-picture-footer {
    max-width: 17.395833333333336vw;
    max-height: 6.481481481481481vh;
    min-width: 197px;
    min-height: 42px;
    margin-top: 0;
  }
}

.casablanca {
  /* UI Properties */
  text-align: left;
  font: normal normal normal 32px/6.018518518518518vh Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .casablanca {
    width: 20.729166666666668vw;
    height: 3.888888888888889vh;
    margin-bottom: 2.962962962962963vh;
  }
}

.footer-adresse-postale {
  text-align: center;
  font: normal normal normal 15px/2.962962962962963vh Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .footer-adresse-postale {
    text-align: left;
    margin-bottom: 1.2037037037037037vh;
  }
}

.footer-infos-legales {
  text-align: center;
  font: normal normal normal 15px/2.962962962962963vh Aileron-Regular;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .footer-infos-legales {
    text-align: left;
    margin-bottom: 1.2037037037037037vh;
  }
}

.footer-map {
  width: calc(100vw - 60px);
  height: 202px;
  background-color: antiquewhite;
  margin-top: 38px;
}

@media only screen and (min-width: $widthMobile) {
  .footer-map {
    width: 20.729166666666668vw;
    background-color: antiquewhite;
    margin-top: 7.685185185185185vh;
  }
}

.qui-somme-nous {
  text-align: left;
  font: normal normal normal 22px/2.685185185185185vh Aileron-Regular;

  color: #e4e5ea;
  border-left: 1px solid #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .qui-somme-nous {
    text-align: left;
    font: normal normal normal 22px/2.685185185185185vh Aileron-Regular;
    height: fit-content;
    color: #e4e5ea;
    border-left: 1px solid #ffffff;
    line-height: 30px;
  }
}

.qui-somme-nous-element {
  position: relative;
  left: 1.875vw;
  margin-bottom: 0.9259259259259258vh;
  cursor: pointer;
}

.footer-bottom-text {
  display: inline-table;
  /* UI Properties */
  text-align: center;
  font: normal normal 500 calc(9px + 0.3125vw) / 1.759259259259259vh Aileron-Regular;
  color: #f7f7fa;
  margin: 52px 0 28px 0;
}

@media only screen and (min-width: $widthMobile) {
  .footer-bottom-text {
    display: flex;
    width: 85.41666666666667vw;
    justify-content: space-between;
    /* UI Properties */
    text-align: left;
    margin: 0;
  }
}
