@import '../common/variables';

.page-profil {
  width: 100vw;
}

.page-profil-section-1-container-background {
  background: #282e49 0% 0% no-repeat padding-box;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-section-1-container-background {
    height: 50vh;
    display: flex;
  }
}

.page-profil-section-1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-section-1-container {
    flex-direction: row;
    align-items: unset;
    margin-top: 10.925925925925926vh;
    margin-left: 25.74074074074074vh;
  }
}

.page-profil-photo {
  max-width: 336px;
  max-height: 477px;
  margin-top: 54px;
  background-color: rgb(168, 181, 177);
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-photo {
    max-width: 16.927083333333336vw;
    max-height: 43.05555555555555vh;
    margin-top: 0;
    background-color: rgb(168, 181, 177);
  }
}

.page-profil-informations {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-informations {
    display: unset;
    margin-left: 4.635416666666667vw;
  }
}

.page-profil-titre-1 {
  /* UI Properties */
  text-align: left;
  font: normal normal 500 calc(12px + 0.6770833333333334vw) / 2.7777777777777777vh Aileron-Regular;
  letter-spacing: 3px;
  color: #c1a367;
  text-transform: uppercase;
  opacity: 1;
  margin: 22px 0 22px 0;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-titre-1 {
    margin: 0 0 3.796296296296296vh 0;
  }
}

.page-profil-nom-text {
  /* UI Properties */
  text-align: center;
  font: normal normal normal 42px/55px Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-nom-text {
    text-align: left;
    font: normal normal normal 3.6458333333333335vw/8.61111111111111vh Aileron-Regular;
    width: 47.44791666666667vw;
  }
}

.page-profil-titre {
  /* UI Properties */
  text-align: center;
  font: normal normal 21px/25px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
  margin-top: 6px;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-titre {
    font: normal normal 1.5104166666666667vw/3.2407407407407405vh Aileron-Regular;
    color: #ffffff;
    margin-top: 0;
    text-align: left;
  }
}

.page-profil-contact {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-contact {
    margin-top: 8.981481481481481vh;
    flex-direction: unset;
    align-items: unset;
  }
}

.img-linkedin {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.page-profil-numero {
  /* UI Properties */
  text-align: left;
  text-decoration: underline;
  font: normal normal 12px/15px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 40px;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-numero {
    font: normal normal 1.09375vw/2.314814814814815vh Aileron-Regular;
    margin-left: 2.8125vw;
    margin-bottom: 0;
  }
}

.page-profil-email {
  /* UI Properties */
  text-align: left;
  text-decoration: underline;
  font: normal normal 12px/15px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
  margin-top: 15px;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-email {
    font: normal normal 1.09375vw/2.314814814814815vh Aileron-Regular;
    margin-left: 3.3333333333333335vw;
    margin-top: 0;
  }
}

.page-profil-buttons {
  margin-top: 10.185185185185185vh;
}

.container-icone-navigation {
  background-color: #39436c;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 8px;
}

.page-profil-paragraph-container {
  margin-left: 30px;
  margin-top: 45px;
  background-color: #ffffff;
  margin-bottom: 77px;
  margin-right: 30px;
  text-align: justify;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-paragraph-container {
    margin-left: 36.09375vw;
    margin-top: 5.555555555555555vh;
    margin-bottom: 15.648148148148147vh;
    margin-right: 0;
  }
}

.page-profil-paragraph {
  /* UI Properties */
  font: normal normal 300 18px/21px Aileron-Regular;
  color: #282e49;
  margin-bottom: 77px;
  text-align: justify;
  line-height: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .page-profil-paragraph {
    width: 35.10416666666667vw;
    /* UI Properties */
    font: normal normal 300 1.09375vw/2.5925925925925926vh Aileron-Regular;
    color: #282e49;
    margin-bottom: 15.648148148148147vh;
    line-height: 22px;

  }
}

.page-profil-buttons-mobile {
  margin-top: 10.185185185185185vh;
  display: flex;
  width: calc(100vw - 128px);
  justify-content: space-between;
  margin: 0 64px 68px 64px;
}

.container-icone-navigation-mobile {
  background-color: #39436c;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 8px;
}

.page-profil-formation {
  /* UI Properties */
  text-align: left;
  font: normal normal 1.3020833333333335vw/2.7777777777777777vh Aileron-Regular;
  color: #282e49;
  text-transform: uppercase;
  opacity: 1;
}

.page-profil-ecole {
  /* UI Properties */
  text-align: left;
  font: normal normal bold 1.09375vw/2.5925925925925926vh Aileron-Regular;
  color: #282e49;
  margin-top: 3.6111111111111107vh;
}

.page-profil-specialte {
  /* UI Properties */
  text-align: left;
  font: normal normal 300 1.09375vw/2.5925925925925926vh Aileron-Regular;
  color: #282e49;
}
