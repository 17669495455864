.ec-button-type {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6866669d;
  border-radius: 3px;
  opacity: 1;
  margin-top: 3.7037037037037033vh;
  display: flex;
  justify-content: space-between;
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
  align-items: center;
  width: 100%;
}

.ec-button-type-label {
  display: flex;
  text-align: left;
  font: normal normal 600 13px/3.9814814814814814vh Aileron-Regular;
  color: black;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 10px;
}
