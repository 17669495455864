@import '../../components/common/variables';

.section-equipe {
  background: #FFFFFF;
}

@media only screen and (min-width: $widthMobile) {
  .section-equipe {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh + 14.7222vh);
  }
}

.section-equipe-container {
  padding-top: 44px;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) and (max-height: 800px) {
  .section-equipe-container {
    scale: 80%;
  }
}

@media only screen and (min-width: $widthMobile) {
  .section-equipe-container {
    padding-top: 0;
  }
}

.equipe-title {
  display: block;
  justify-content: space-between;
}

@media only screen and (min-width: $widthMobile) {
  .equipe-title {
    display: flex;
    justify-content: space-between;
  }
}

.equipe-title-left {
  /* UI Properties */
  text-align: left;
  font: calc(32px + 1.5625vw) Aileron-Regular;
  color: #282e49;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: $widthMobile) {
  .equipe-title-left {
    /* UI Properties */
    text-align: left;
    color: #282e49;
    display: block;
  }
}

.nowrap {
  white-space: nowrap;
}

.equipe-title-right {
  /* UI Properties */
  text-align: left;
  font: normal normal normal calc(24px + 0.9375vw) Aileron-Regular;
  color: #282e49;
  margin: 20px 30px 28px 0;
  white-space: nowrap;
}
@media only screen and (min-width: $widthMobile) {
  .equipe-title-right {
    /* UI Properties */
    text-align: left;
    color: #282e49;
    opacity: 1;
    margin: 0 0 0 8.020833333333334vw;
  }
}

.divider-equipe-expert {
  border-bottom: 1px solid #282e49;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 40px;
}

@media only screen and (min-width: $widthMobile) {
  .divider-equipe-expert {
    width: 20.729166666666668vw;
    align-self: flex-start;
  }
}

.equipe-profiles {
  width: calc(100vw - 60px);
  margin-top: 3.518518518518518vh;
}

@media only screen and (min-width: $widthMobile) {
  .equipe-profiles {
    justify-content: space-between;
    display: flex;
    width: 55.677083333333336vw;
    margin-top: 3.518518518518518vh;
  }
}

.equipe-profil {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.equipe-profil-img {
  max-height: 243px;
  background-color: #282e49;
  max-width: 171px;
}

@media only screen and (min-width: $widthMobile) {
  .equipe-profil-img {
    max-width: 10.520833333333334vw;
    max-height: 27.314814814814813vh;
    background-color: #282e49;
  }
}
.profil-name {
  /* UI Properties */
  text-align: center;
    font: normal normal bold calc(17px + 0.2084vw)/2.3148148148vh Aileron-Regular;
    color: #282e49;
    margin-top: 3.388889vh;
    min-width: 171px;
}

.profil-poste {
  text-align: center;
    font: normal normal 500 15px/1.7592592593vh Aileron-Regular;
    color: #282e49;
    margin-top: 1.462963vh;
    margin-bottom: 16px;
    width: 171px;
    line-height: 23px;
}

@media only screen and (min-width: $widthMobile) {
  .profil-poste {
    margin-bottom: 0;
  }
}

.link-tout-equipe-mobile {
  height: 27.314814814814813vh;
  width: calc(33.33vw - 60px);
  min-width: 171px;
  text-decoration: underline;
  font: normal normal calc(15px + 0.364584vw) / 27.314814814814813vh Aileron-Regular;
  color: #c1a367;
  text-transform: uppercase;
  cursor: pointer;
}

.link-tout-equipe {
  text-align: center;
  text-decoration: underline;
  font: normal normal calc(15px + 0.364584vw) / 100% Aileron-Regular;
  color: #c1a367;
  text-transform: uppercase;
  width: 55.677083333333336vw;
  margin-top: 4.62962962962963vh;
  cursor: pointer;
}
