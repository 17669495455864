.section-2-fcp-management {
  width: 100vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.section-titre-2-container {
  background-color: #ffffff;
  margin-left: 21.666666666666668vw;
  margin-right: 14.583333333333334vw;
}

.section-titre-fcp-management {
  background-color: #ffffff;
  display: flex;
}

.section-titre-fcp-management-text {
  width: 50vw;
  height: 13.61111111111111vh;
  text-align: left;
  font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  color: #282e49;
  display: flex;
  white-space: nowrap;
}

.page-afg-buttons {
  margin-left: 150px;
}

.afg-container-icone-navigation {
  background-color: #39436c;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 8px;
}

.page-afg-buttons-mobile {
  display: flex;
  width: calc(100vw - 128px);
  justify-content: space-between;
  margin: 30px 64px 0 64px;
  z-index: 10;
}

.afg-container-icone-navigation-mobile {
  background-color: #39436c;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 8px;
}

.fcp-management-divider {
  width: 20.729166666666668vw;
  border-bottom: 1px solid #282e49;
  margin-top: 18vh;
}

.fcp-management-paragraph {
  width: 63.75vw;
  height: 7.037037037037036vh;
  /* UI Properties */
  text-align: left;
  font: normal normal 300 1.2vw/3.518518518518518vh Aileron-Regular;
  color: #282e49;
  opacity: 1;
  margin-top: 3.9814814814814814vh;
  margin-bottom: 3.9814814814814814vh;
}
