@import '../common/variables';

.two-pages-container {
  display: flex;
}

.notre-equipe-titre-principal {
  height: 200px;
  align-items: center;
  width: 100vw;
  background-color: #282e49;
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-titre-principal {
    align-items: center;
    height: 49.907407407407405vh;
    width: 100vw;
    background-color: #282e49;
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;
  }
}

.notre-equipe-titre-principal-text {
  text-align: left;
  font: normal normal normal calc(32px + 1.5625vw) Aileron-Regular;
  color: #ffffff;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-titre-principal-text {
    margin-left: 21.666666666666668vw;
    text-align: left;
    color: #ffffff;
  }
}

.notre-equipe-sec2-container {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-sec2-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.notre-equipe-titre-2-text {
  text-align: left;
  font: normal normal normal calc(25px + 1.5625vw) Aileron-Regular;
  color: #282e49;
  opacity: 1;
  margin: 30px 30px 0 30px;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-titre-2-text {
    margin: 13.333333333333332vh 0 0 0;
    text-align: left;
    font: normal normal normal calc(32px + 1.5625vw) Aileron-Regular;
    color: #282e49;
    opacity: 1;
    width: 56.66666666666667vw;
  }
}

.divider-notre-equipe {
  margin: 15px 0 0 30px;
  width: 236px;
  border-bottom: 1px solid #c1a367;
}

@media only screen and (min-width: $widthMobile) {
  .divider-notre-equipe {
    margin: 3.7037037037037033vh 0 0 0;
    width: 20.729166666666668vw;
    border-bottom: 1px solid #282e49;
  }
}

.notre-equipe-paragraph-container {
  /* UI Properties */
  text-align: left;
  font: normal normal 300 calc(16px + 0.2604166666666667vw) Aileron-Regular;
  color: #282e49;
  margin: 33px 30px 45px 30px;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-paragraph-container {
    /* UI Properties */
    text-align: left;
    font: normal normal 300 calc(16px + 0.2604166666666667vw) Aileron-Regular;
    color: #282e49;
    width: 56.66666666666667vw;
    margin: 4.259259259259259vh 0 0 0;
  }
}

.notre-equipe-paragraph {
  /* UI Properties */
  text-align: justify;
  margin-bottom: 20px;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-paragraph {
    /* UI Properties */
    color: #282e49;
    width: 56.66666666666667vw;
  }
}

.notre-equipe-profil {
  min-width: 33%;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-profil {
    align-items: start;
    margin-right: unset;
    transition-duration: 1s;
    scale: 1;
    :hover {
      scale: 1.03;
      transition-duration: 1s;
    }
    transition-duration: 1s; /* Add this line to apply transition on mouse-out */
  }
}

.notre-equipe-profil-container {
  min-width: 33%;
  margin-bottom: 6.296296296296296vh;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 1s;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-profil-container {
    align-items: center;
    margin-right: unset;
  }
}

.notre-equipe-profiles-background {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282e49;
}

.notre-equipe-profiles {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 25%;
  margin-left: 30px;
  margin-top: 30px;
}

@media only screen and (max-width: 472px) {
  .notre-equipe-profiles {
    justify-content: center;
  }
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-profiles {
    display: flex;
    flex-wrap: wrap;
    width: 55.677083333333336vw;
    margin-top: 8vh;
    flex-basis: 25%;
    margin-left: 0;
  }
}

.notre-equipe-profil-img {
  width: 171px;
  height: 243px;
  max-width: 171px;
  max-height: 243px;
  background-color: #282e49;
  cursor: pointer;
  transition-duration: 1s;
}

@media only screen and (min-width: $widthMobile) {
  .notre-equipe-profil-img {
    // width: 207px;
    // height: 295px;
    // max-width: 207px;
    // max-height: 295px;
    background-color: #282e49;
    cursor: pointer;
  }
}

.notre-equipe-profil-img-container {
  text-align: left;
}

.ne-profil-name {
  text-align: center;
  font: normal normal bold calc(14px + 0.2604166666666667vw)/18px Aileron-Regular;
  color: #FFFFFF;
  margin-top: 1.3888888888888888vh;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .ne-profil-name {
    text-align: center;
    font: normal normal bold calc(12px + 0.2604166666666667vw)/18px Aileron-Regular;
    margin-top: 1.3888888888888888vh;
    max-width: 12.520833333333334vw;
    cursor: pointer;
  }
}

.ne-profil-poste {
  text-align: center;
  font: normal normal 500 calc(10px + 0.2604166666666667vw)/19px Aileron-Regular;
  color: #FFFFFF;
  margin-top: 0.4629629629629629vh;
  max-width: 173px;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .ne-profil-poste {
    max-width: 11.520833333333334vw;
  }
}

.div-lottie {
  display: flex;
  flex-direction: column;
  transform: translate(0, -25.5vh);
}
