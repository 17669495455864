.margin-replacer {
  height: 5.37037037037037vh;
}

.menu-indic {
  font: normal normal normal 32px/39px Aileron-Regular;
  letter-spacing: 0px;
  color: #282e49;
  margin-left: 15px;
  text-transform: uppercase;
}
.app-bar-menu-button-container {
  text-align: left;
  flex-direction: column;
  /* UI Properties */
  font: normal normal normal calc(1.5104167vw + 28px) Aileron-Regular;
  color: #282e49;
  opacity: 1;
}

.app-bar-menu-button:hover {
  transform: translate(0.5208333333333334vw, 0);
  transition-duration: 0.5s;
  color: #c1a367;
  cursor: pointer;
}

.app-bar-menu-divider {
  width: 150px;
  /* UI Properties */
  border-bottom: 4px solid #c1a367;
  opacity: 1;
  margin-top: 3.4259259259259256vh;
  margin-bottom: 3.4259259259259256vh;
}

.menu-indic-mobile {
  color: #c1a367;
  font: normal normal normal 16px / 19px Aileron-Regular;
  text-transform: uppercase;
}

.app-bar-menu-button-vl {
  text-align: left;
  font: normal normal normal calc(1vw + 27px) Aileron-Regular;
  color: #86878c;
  cursor: pointer;
}
