.section-inscription {
  width: 100vw;
  display: flex;
  background: #f7f7fa 0% 0%;
  height: 100vh; //989
}

.section-inscription-container {
  position: relative;
  top: 6.203703703703703vh;
  left: 13.90625vw;
  background: #ffffff;
  width: 72.1875vw;
  height: 74.53703703703704vh;
}

.section-inscription-container-bis {
  position: relative;
  top: 5.833333333333333vh;
  left: 7.760416666666667vw;
}

.inscription-title {
  width: 24.010416666666668vw;
  height: 7.592592592592592vh;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;

  color: #282e49;
  opacity: 1;
}

.divider-inscription {
  margin-top: 2.962962962962963vh;
  width: 20.729166666666668vw;
  border-bottom: 1px solid #282e49;
  margin-bottom: 6.388888888888888vh;
}

.inscription-menu {
  width: 20.989583333333336vw;
  height: 4.722222222222222vh;
  text-align: left;
  margin-bottom: 3.0555555555555554vh;
}

.field-nom {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6866669d;
  border-radius: 3px;
  margin-top: 3.0555555555555554vh;
  padding-left: 0.78125vw;
  padding-right: 1.3888888888888888vh;
  width: 56.71875vw;
  height: 4.722222222222222vh;
  color: #6866669d;
}

input::placeholder {
  font: normal normal 100 1.0416666666666667vw/3.9814814814814814vh Aileron-Regular;
  color: #8d8a8a9d;
  text-transform: uppercase;
}

.field-inscription {
  width: 56.71875vw;
  margin-bottom: 1.4814814814814814vh;
}

.field-inscription-commentaire {
  width: 56.71875vw;
  height: 13.888888888888888vh;
  margin-bottom: 1.4814814814814814vh;
  text-align: left;
}

.buttons-container-inscription {
  width: 56.71875vw;
  text-align: flex-end;
}

.buttons-container-inscription-bis {
  display: inline-block;
  text-align: left;
}

.buttons-container-inscription-bis-2 {
  display: flex;
}

.field-inscription-envoyer {
  text-align: right;
  width: 13.958333333333334vw;
  margin-left: 1.40625vw;
}
