.vl-button-type {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6866669d;
  border-radius: 3px;
  opacity: 1;

  display: flex;
  justify-content: space-between;
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
  align-items: center;
  width: 100%;
}

.button-type-label {
  display: flex;
  text-align: left;
  font: normal normal 300 11px Aileron-Regular;
  color: #6866669d;
  text-transform: uppercase;
  opacity: 1;
}
