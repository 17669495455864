@import '../../components/common/variables';

.section-se-connecter {
  width: 100vw;
  display: flex;
  background: #FFFFFF;
}

.sc-section-se-connecter-container {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 11.388888888888888vh;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .sc-section-se-connecter-container {
    margin-top: 10vh;
    margin-left: 13.90625vw;
    width: 72.1875vw;
    margin-bottom: 11.388888888888888vh;
    margin-right: unset;
  }
  
}

.section-nous-contacter-container-bis {
  position: relative;
  margin-top: 5.833333333333333vh;
  margin-left: 7.760416666666667vw;
  margin-bottom: 6.481481481481481vh;
}

.nous-contacter-title {
  /* UI Properties */
  text-align: left;
  font: normal normal normal calc(10px + 3vw)/6.018518518518518vh Aileron-Regular;

  color: #282e49;
  opacity: 1;
  margin-bottom: 30px;
}

.divider-nous-contacter {
  width: 7.239583333333334vw;
  border-bottom: 1px solid #282e49;
  margin-top: 30px;
  margin-bottom: 30px;
}

.field-nom {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6866669d;
  border-radius: 3px;
  margin-top: 3.0555555555555554vh;
  padding-left: 0.78125vw;
  padding-right: 1.3888888888888888vh;
  width: 56.71875vw;
  color: #6866669d;
}

.sc-field-nous-contacter {
  max-width: 500px;
  margin-bottom: 1.4814814814814814vh;
}

.field-nous-contacter-commentaire {
  width: 56.71875vw;
  margin-bottom: 1.4814814814814814vh;
  text-align: left;
}

.sc-field-nous-contacter-envoyer {
  max-width: 500px;
  text-align: right;
}
