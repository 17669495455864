@import '../../components/common/variables';

@media only screen and (min-width: $widthMobile) {
  .section-nos-clients {
    display: flex;
    flex-direction: column;
    height: calc(100vh + 14.7222vh);
    justify-content: center;
    width: 100vw;
    min-width: 1233px;
  }
}

@media only screen and (min-width: 1200px) {
  .section-nos-clients {
    align-items: center;
  }
}

.section-nos-clients-container {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $widthMobile) and (max-height: 800px) {
  .section-nos-clients-container {
    height: 100vh;
    scale: 75%;
  }
}

@media only screen and (min-width: 1200px) {
  .section-nos-clients-container {
    display: flex;
    flex-direction: column;
  }
}

.nos-clients-title {
  /* UI Properties */
  text-align: left;
  font: normal normal normal calc(32px + 1.5625vw) / calc(min(6.01vh, calc(6.01 * 1080 * 0.01px))) Aileron-Regular;
  display: flex;
  align-items: center;
  color: #ffffff;
  border-bottom: 1px solid #e4e5ea;
  width: fit-content;
  margin-top: 44px;
  margin-bottom: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .nos-clients-title {
    height: 10.925925925925926vh;
    margin-top: unset;
  }
}

.three-cards-container {
  width: 100vw;
  white-space: nowrap;
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .three-cards-container {
    overflow-x: unset;
    overflow-y: unset;
    margin-left: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.three-cards {
  position: relative;
  display: unset;
  justify-content: space-between;
  background-color: transparent;
  white-space: initial;
}

@media only screen and (min-width: $widthMobile) {
  .three-cards {
    position: relative;
    display: flex;
    width: 1140px;
    justify-content: space-between;
    background-color: transparent;
    margin-right: 0;
  }
}

.card-client {
  background: white;
  width: 360px;
  height: 450px;
  opacity: 85%;
  display: flex;
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;}

@media only screen and (min-width: $widthMobile) {
  .card-client {
    background: white;
    width: 360px;
    opacity: 85%;
    margin-top: unset;
  }
}

.card-content-container {
  margin-top: calc(min(4.351851851851851vh, calc(4.351851851851851 * 1080 * 0.01px)));
  margin-left: 56px;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .card-content-container {
    margin-top: calc(min(3.6111111111111107vh, calc(3.6111111111111107 * 1080 * 0.01px)));
    margin-left: calc(min(2.5vw, calc(2.5 * 1920 * 0.007px)));
    margin-right: calc(min(2.5vw, calc(2.5 * 1920 * 0.007px)));
    transition: transform 0.3s ease;
  }
}

.logo {
  text-align: left;
  color: #c1a367;
}

.logo > img {
  max-height: 60px;
}

.card-title {
  text-align: left;
  font: normal normal normal 36px Aileron-Regular;
  margin-top: 2.4074074074074074vh;
  color: #282e49;
  opacity: 1;
  height: 80px;
  line-height: 35px;
}

@media only screen and (min-width: $widthMobile) {
  .card-text {
    text-align: left;
    font: normal normal 300 18px Aileron-Regular;
    color: #282e49;
    opacity: 1;
    margin-top: 80px;
    position: relative;
    line-height: 35px;
  }

.card-title {
  text-align: left;
  font: normal normal normal 30px Aileron-Regular;
  margin-top: 2.4074074074074074vh;
  color: #282e49;
  opacity: 1;
  height: 50px;
  line-height: 35px;
}
}

.card-text {
  /* UI Properties */
  text-align: left;
  font: normal normal 300 20px Aileron-Regular;
  color: #282e49;
  opacity: 1;
  margin-top: 80px;
  position: relative;
  line-height: 30px;
}

.buttons-scroll-container {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.buttons-scroll {
  margin-top: 50px;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;
}
