@import "../../components/common/variables";

.section-presentation-generale {
  width: 100%;
  display: block;
}

@media only screen and (min-width: $widthMobile) {
  .section-presentation-generale {
    background: #f7f7fa;
    height: calc(100vh + 14.7222vh); //989
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.section-presentation-generale-container {
  width: 100%;
  display: block;
}

@media only screen and (min-width: $widthMobile) {
  .section-presentation-generale-container {
    display: flex;
  }
}

@media only screen and (min-width: $widthMobile) and (max-height: 900px) {
  .section-presentation-generale-container {
    scale: 80%;
  }
}

@media only screen and (min-width: $widthMobile) and (max-height: 700px) {
  .section-presentation-generale-container {
    scale: 60%;
  }
}

.side-presentation-generale-left {
  width: 100vw;
}

@media only screen and (min-width: $widthMobile) {
  .side-presentation-generale-left {
    display: flex;
    flex-direction: column;
  }
}

.side-presentation-generale-right {
  width: 100vw;
}

@media only screen and (min-width: $widthMobile) {
  .side-presentation-generale-right {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }
}

@media only screen and (min-width: $widthMobile) {
  .left-side-cotnainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50vw;
    position: relative;
  }
}

.title-presentation-generale {
  text-align: left;
  font: normal normal normal calc(42px + 1.0416vw) Aileron-Regular; // 62/65
  color: #282e49;
  margin-top: 26px; //200
  margin-left: 30px; //416
  width: fit-content;
}

@media only screen and (min-width: $widthMobile) {
  .title-presentation-generale {
    margin-right: 8.020833333333334vw;
    margin-top: 0;
  }
}

.presentation-generale-divider {
  border-bottom: 1px solid #c1a367;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.presentation-generale-photo {
  width: 100vw; //814
  margin-top: 4.166666666666666vh; //45
  text-align: end;
}

.presentation-generale-photo > img {
  max-width: 100vw; //814
}

.presentation-generale-photo-desktop {
  text-align: start;
  margin-right: 8.020833333333334vw;
  width: 100%;
}

.presentation-generale-img-container {
  width: 100%;

  > img {
    height: 600px;
    position: absolute;
    width: 100%;
  }
}

@media only screen and (min-width: $widthMobile) {
  .presentation-generale-photo {
    width: 42.395833333333336vw; //814
    margin-right: 8.020833333333334vw;
  }
}

.title-gestion-actifs {
  text-align: left;
  font: normal normal normal calc(32px + 0.5208333333333334vw) /
    4.166666666666666vh Aileron-Regular; //42/45
  color: #282e49;
  margin: 44px 0 0 30px;
}

@media only screen and (min-width: $widthMobile) {
  .title-gestion-actifs {
    margin: 0 0 0 0; //200;//200
  }
}

.paragraph-gestion-actifs {
  text-align: left;
  font: normal normal 300 calc(17px + 0.208vw) Aileron-Regular; //21/25
  color: #282e49;
  padding: 3.148148148148148vh 30px 0 30px; //34
  text-align: justify;
  line-height: 35px;
}

@media only screen and (min-width: $widthMobile) {
  .paragraph-gestion-actifs {
    font: normal normal 300 calc(15px + 0.208vw) Aileron-Regular; //21/25
    padding: 0; //34
    margin-right: 10vw;
    line-height: 30px;
  }
}

.rounded {
  border-bottom: 1px solid #c1a367;
  width: 20.32vw;
  text-align: left;
  margin-top: 44px;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .rounded {
    width: 4.53125vw;
    margin-top: 3vh;
    margin-left: 0;
  }
}

.facteurs-cles {
  text-align: left;
  font: normal 800 normal calc(32px + 0.26vw) Aileron-Regular;
  color: #282e49;
  height: 70px; //49
  margin-top: 44px; //86
  margin-left: 30px;
  white-space: nowrap;
}

@media only screen and (min-width: $widthMobile) {
  .facteurs-cles {
    font: normal 800 normal calc(24px + 0.26vw) Aileron-Regular;
    align-items: center;
    margin-top: unset; //86
    display: flex;
    margin-left: 0;
  }
}

.numerotation-facteurs-cle {
  /* UI Properties */
  text-align: left;
  font: normal normal bold calc(21px + 0.26vw) Aileron-Regular;
  color: #c1a367;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .numerotation-facteurs-cle {
    margin-left: 0;
  }
}

.element-list-facteurs-cles {
  display: flex;
}

.valeur-facteurs-cles {
  /* UI Properties */
  text-align: left;
  font: normal normal normal calc(21px + 0.26vw) Aileron-Regular;
  background-color: #282e49;
  color: white;
  margin-bottom: 5px;
  padding: 0 10px 0 10px;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .valeur-facteurs-cles {
    margin-left: unset;
  }
}

.last-facteur {
  margin-bottom: 52px;
}

@media only screen and (min-width: $widthMobile) {
  .last-facteur {
    margin-bottom: 0px;
  }
}

.band-facteurs-container {
  display: flex;
  margin: 30px 30px 40px 30px;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (min-width: $widthMobile) {
  .band-facteurs-container {
    display: flex;
    margin: 0;
    width: 100%;
  }
}

.band-facteurs {
  display: flex;
  flex-direction: column;
  font: normal normal normal calc(20px) Aileron-Regular;
  height: 100%;
  > * {
    margin: 20px 0 20px 0;
  }
  > *:first-child {
    margin: 0 0 20px 0;
  }
}

@media only screen and (min-width: $widthMobile) {
  .band-facteurs {
    width: 100%;
    > * {
      margin: 20px 0 calc(5px + 1.38vh) 0;
    }
    > *:first-child {
      margin: 0 0 calc(5px + -1.98vh) 0;
    }
  }
}

.facteur-container {
  display: flex;
  align-items: center;
  > img {
    margin-right: 50px;
    max-height: 40px;
    width: 40px;
  }
}

@media only screen and (min-width: $widthMobile) {
  .facteur-container {
    color: #282e49;
    background-color: white;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;    
    display: flex;
    width: 75%;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-top: 15px;
    align-items: left;
    margin-bottom: -10px;
    font: normal 600 normal calc(20px) Aileron-Regular;

    > img {
      max-height: 50px;
      margin-right: 20px;
    }
  }
  .facteur-container:hover {
    background-color: rgba(230, 230, 230);
     
  }
}

.facteur {
  width: fit-content;
  text-align: left;
}

.facteur-text-container {
  text-align: left;
}
