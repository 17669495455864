@import '../../common/variables';

.cards {
  position: relative;
}

.card {
  position: absolute;
  width: calc(100vw - 60px);
  max-width: 668px;
  border-radius: 28px;
  height: 650px;
}

@media only screen and (min-width: $widthMobile) {
  .card {
    width: 34.79166666666667vw;
    height: calc(max(65.92592592592592vh, 580px));
    max-height: 900px;
    min-height: 630px;
  }
}

.icons-container {
  display: flex;
  align-items: center;
  margin-top: 3.333333333333333vh;
}

@media only screen and (min-width: $widthMobile) {
  .icons-container {
    margin-top: 31px;
  }
}

.icon-back {
  width: 100%;
  padding-right: 2.34375vw;
  text-align: end;
}

.icon-forward {
  width: 100%;
  padding-left: 2.34375vw;
  text-align: start;
}

.classification-frequence-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .classification-frequence-container {
    display: flex;
    align-items: center;
    margin-top: 6.203703703703703vh;
  }
}

.labels-classification-frequence-container {
  box-sizing: border-box;
  text-align: left;
  font: normal 1 16px / 2.314814814814815vh Aileron-Regular;
  width: 100%;
  text-align: right;
  margin-right: 13px;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
}

@media only screen and (min-width: $widthMobile) {
  .labels-classification-frequence-container {
    margin-right: 0.8854166666666667vw;
    font: normal 1 calc(18px + 0.15625vw) / 2.314814814814815vh Aileron-Regular;
  }
}

.values-classification-frequence-container {
  box-sizing: border-box;
  text-align: left;
  font: normal 800 16px / 2.314814814814815vh Aileron-Regular;
  opacity: 1;
  width: 100%;
  text-align: left;
  margin-left: 13px;
}

@media only screen and (min-width: $widthMobile) {
  .values-classification-frequence-container {
    margin-left: 0.8854166666666667vw;
    font: normal 800 calc(18px + 0.15625vw) / 2.314814814814815vh Aileron-Regular;
  }
}

.card-large-title {
  margin-left: 22px;
  text-align: left;
  font: normal normal bold calc(24px + 0.364vw) Aileron-Regular;
  text-transform: uppercase;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .card-large-title {
    margin-left: calc(min(3.4375vw, calc(3.4375 * 1920 * 0.01px)));
    margin-top: calc(min(4.62962962962963vh, calc(4.62962962962963 * 1080 * 0.01px)));
  }
}

.card-large-title-text {
  font-family: Aileron-Regular;
  width: 230px;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .card-large-title-text {
    width: fit-content;
  }
}

.divider {
  box-sizing: border-box;
  border-left: 1px solid green;
  height: 5.37037037037037vh;
}

.classificiation {
  margin-bottom: 1.574074074074074vh;
}

.divider-larg-title {
  margin-left: 22px;
  box-sizing: border-box;
  border-bottom: 3px solid green;
  width: 15.277777777777777vh;
  border-color: #c1a367;
}

@media only screen and (min-width: $widthMobile) {
  .divider-larg-title {
    margin-left: calc(min(3.4375vw, calc(3.4375 * 1920 * 0.01px)));
  }
}

.paragraph-stacked-card {
  text-align: left;
  font: normal normal normal calc(12px + calc(min(0.52083vw, calc(0.52083 * 1920 * 0.01px))))/28px Aileron-Regular;
  margin: 10px 22px 33px 22px;
  min-height: 74px;
  line-height: 35px;
}

@media only screen and (min-width: $widthMobile) {
  .paragraph-stacked-card {
    max-width: 600px;
    line-height: 25px;
    margin: 1.2962962962962963vh calc(min(3.4375vw, calc(3.4375 * 1920 * 0.01px))) 0 calc(min(3.4375vw, calc(3.4375 * 1920 * 0.01px)));
  }
}

.telechargements-container {
  text-align: left;
  margin: 28px 22px 22px 22px;
}

@media only screen and (min-width: $widthMobile) {
  .telechargements-container {
    text-align: left;
    margin: calc(min(8.34vh, calc(8.34 * 1080 * 0.004px))) 30px calc(min(5.1vh, calc(5.1 * 1080 * 0.01px))) calc(min(3.4375vw, calc(3.4375 * 1920 * 0.01px)));
  }
}

.telechargement {
  font: normal normal 600 calc(0.1042vw + 15px) / 19px Aileron-Regular;
  display: flex;
  margin-bottom: 14px;
  cursor: pointer;
}

.btn-ensavoir-plus {
  position: absolute;
  right: 50px;
  bottom: 40px;
  border: 1px solid rgb(40, 46, 73);
  padding: 10px;
  border-radius: 100px;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  &:hover {
    background-color: #c1a367;
  }
}
