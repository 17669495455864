@import '../../components/common/variables';

.espace-client-titre-principal {
  height: 200px;
  width: 100vw;
  background-color: #282e49;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .espace-client-titre-principal {
    height: 14.72vh;
    width: 100vw;
  }
}

.espace-client-titre-principal-text {
  margin-left: 30px;
  text-align: left;
  font: normal normal normal 38px/49px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
  position: absolute;
}

@media only screen and (min-width: $widthMobile) {
  .espace-client-titre-principal-text {
    margin-left: 21.666666666666668vw;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #ffffff;
    opacity: 1;
    position: absolute;
  }
}

.section2-container {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .section2-container {
    display: flex;
    justify-content: unset;
  }
}

.side-bar-left {
  width: 20%;
  background-color: #F7F7FA;
  display: flex;
  justify-content: center;
}

.menu-container {
  text-align: left;
  margin: 30px 10px 0 10px;
}

.icon-titre-container {
  display: flex;
}

.vl-icon-img {
  max-height: 25px;
}

.menu-button-el {
  text-align: left;
  font: normal normal 600 19px/25px Aileron-Regular;
  cursor: pointer;
  margin-left: 10px;
}

.divider-menu-ec {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #282E4933;
}

.espace-client-docs-background {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $widthMobile) {
  .espace-client-docs-background {
    width: 80%;
    align-items: center;
  }
}

.ec-doc-pdf {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .ec-doc-pdf {
    min-width: 33%;
    align-items: flex-start;
    margin-right: unset;
    transition-duration: 1s; /* Add this line to apply transition on mouse-out */
  }
}

.ec-doc-pdf-container {
  min-width: 33%;
  margin-bottom: 6.296296296296296vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 0.5s;
}

@media only screen and (min-width: $widthMobile) {
  .ec-doc-pdf-container {
    align-items: center;
    margin-right: unset;
  }
}

.title-pdfs {
  text-align: left;
  font: normal normal normal 42px/65px Aileron-Regular;
  margin-top: 30px;
  border-bottom: 1px solid black;
  width: fit-content;
  height: 100px;
}

.ec-docs-pdf {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $widthMobile) {
  .ec-docs-pdf {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 55.677083333333336vw;
    margin-top: 8vh;
    flex-basis: 25%;
    justify-content: center;
    margin-left: unset;
  }
}

.ec-doc-pdf-img {
  min-width: 70px;
  max-height: 100px;
  cursor: pointer;
  transition-duration: 1s;
}

@media only screen and (min-width: $widthMobile) {
  .ec-doc-pdf-img {
    max-width: 97px;
    max-height: 113px;
    cursor: pointer;
  }
}

.notre-equipe-profil-img-container {
  text-align: left;
}

.ec-pdf-title {
  text-align: center;
  font: normal normal bold calc(16px + 0.2604166666666667vw)/18px Aileron-Regular;
  color: #282e49;
  margin-top: 1.3888888888888888vh;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .ec-pdf-title {
    text-align: center;
    font: normal normal bold calc(16px + 0.2604166666666667vw)/18px Aileron-Regular;
    color: #282e49;
    margin-top: 1.3888888888888888vh;
    max-width: 10.520833333333334vw;
    cursor: pointer;
  }
}

.ec-pdf-sub-title {
  text-align: center;
  font: normal normal 500 calc(11px + 0.2604166666666667vw)/19px Aileron-Regular;
  color: #282e49;
  margin-top: 0.4629629629629629vh;
  cursor: pointer;
  max-width: 250px;
}

.np-pdf-tooltip {
  position: absolute;
  background-color: grey;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  margin-top: 180px;
}
