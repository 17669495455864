.mobile-landscape-container {
  background-color: #282e49;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-landscape-img-container > img {
  max-width: 100px;
  max-height: 100px;
}

.mobile-landscape-text-1 {
  font: normal normal 500 28px Aileron-Regular;
  color: white;
  margin: 15px 0 15px 0;
}

.mobile-landscape-text-2 {
  font: normal normal 500 16px Aileron-Regular;
  color: white;
  margin: 0 30px 0 30px;
  text-align: center;
}