@import '../../components/common/variables';

.nos-publications-titre-principal {
  height: 200px;
  width: 100vw;
  background-color: #282e49;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .nos-publications-titre-principal {
    height: 50vh;
    width: 100vw;
  }
}

.nos-publications-titre-principal-text {
  margin-top: 70px;
  margin-left: 30px;
  text-align: left;
  font: normal normal normal 38px/49px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
  position: absolute;
}

@media only screen and (min-width: $widthMobile) {
  .nos-publications-titre-principal-text {
    margin-top: 22vh;
    margin-left: 21.666666666666668vw;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #ffffff;
    opacity: 1;
    position: absolute;
  }
}

.nos-publications-titre-2-text {
  margin-top: 30px;
  margin-left: 30px;
  text-align: left;
  font: normal normal normal 20px/30px Aileron-Regular;
  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .nos-publications-titre-2-text {
    margin-top: 13.333333333333332vh;
    margin-left: 21.666666666666668vw;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  }
}

.divider-nos-publications {
  margin-top: 30px;
  width: 200px;
  border-bottom: 1px solid #282e49;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .divider-nos-publications {
    margin-top: 3.7037037037037033vh;
    width: 20.729166666666668vw;  
    border-bottom: 1px solid #282e49;
    margin-left: 21.666666666666668vw;
  }
}

.nos-publications-paragraph {
  /* UI Properties */
  margin-left: 30px;
  text-align: left;
  font: normal normal 300 14px/20px Aileron-Regular;
  color: #282e49;
  margin-top: 30px;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .nos-publications-paragraph {
    /* UI Properties */
    margin-left: 21.666666666666668vw;
    text-align: left;
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    color: #282e49;
    width: 56.66666666666667vw;
    margin-top: 3.7037037037037033vh;
    margin-right: unset;
  }
}

.nos-publications-docs-background {
  background-color: #f7f7fa;
  display: flex;
  flex-direction: column;
}

.doc-pdf {
  min-width: 33%;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (min-width: $widthMobile) {
  .doc-pdf {
    margin-right: unset;
    transition-duration: 1s; /* Add this line to apply transition on mouse-out */
  }
}

.doc-pdf-container {
  min-width: 33%;
  margin-bottom: 6.296296296296296vh;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 0.5s;
}

@media only screen and (min-width: $widthMobile) {
  .doc-pdf-container {
    align-items: center;
    margin-right: unset;
  }
}

.docs-pdf {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 25%;
  margin-left: 30px;
}

@media only screen and (max-width: 472px) {
  .docs-pdf {
    justify-content: center;
  }
}

@media only screen and (min-width: $widthMobile) {
  .docs-pdf {
    display: flex;
    flex-wrap: wrap;
    width: 55.677083333333336vw;
    margin-top: 8vh;
    flex-basis: 25%;
    margin-left: 21.666666666666668vw;
  }
}

.doc-pdf-img {
  max-width: 120px;
  max-height: 243px;
  transition-duration: 1s;
}

@media only screen and (min-width: $widthMobile) {
  .doc-pdf-img {
    max-width: 97px;
    max-height: 113px;
  }
}

.notre-equipe-profil-img-container {
  text-align: left;
}

.np-pdf-title {
  text-align: center;
  font: normal normal bold calc(16px + 0.2604166666666667vw)/18px Aileron-Regular;
  color: #282e49;
  margin-top: 1.3888888888888888vh;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: $widthMobile) {
  .np-pdf-title {
    text-align: center;
    font: normal normal bold calc(16px + 0.2604166666666667vw)/18px Aileron-Regular;
    color: #282e49;
    margin-top: 1.3888888888888888vh;
    max-width: 10.520833333333334vw;
  }
}

.np-pdf-sub-title {
  text-align: center;
  font: normal normal 500 calc(11px + 0.2604166666666667vw)/19px Aileron-Regular;
  color: #282e49;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.4629629629629629vh;
}

.np-pdf-tooltip {
  position: absolute;
  background-color: grey;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  margin-top: 180px;
}
