@import '../../../components/common/variables';

.section-carac-gestion {
  background-color: #f7f7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-carac-gestion {
    height: calc(65vh + 14.7222vh);
  }
}

.section-titre-cara {
  display: flex;
  margin-top: 40px;
  margin-left: 30px;
  max-width: fit-content;
  word-wrap: break-word;
  text-align: left;
  font: normal normal normal 30px/35px Aileron-Regular;
  color: #282e49;
  opacity: 1;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-cara {
    margin-top: 18vh;
    margin-left: 19.5vw;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #282e49;
    opacity: 1;
  }
}
.divider-carc {
  border-bottom: 1px solid #c1a367;
  width: 229px;
  margin-left: 30px;
  margin-top: 16px;
}

@media only screen and (min-width: $widthMobile) {
  .divider-carc {
    border-bottom: 1px solid #282e49;
    width: 20.729166666666668vw;
    margin-left: 19.5vw;
    margin-top: 4.0740740740740735vh;
  }
}

.section-titre-2-text {
  position: relative;
  margin-top: 38px;
  margin-left: 21.666666666666668vw;
  width: 50vw;
  height: 13.61111111111111vh;
  text-align: left;
  font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-2-text {
    position: relative;
    margin-top: 13.333333333333332vh;
    margin-left: 21.666666666666668vw;
    width: 50vw;
    height: 13.61111111111111vh;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #282e49;
  }
}

.carac-column-container {
  display: unset;
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
}

@media only screen and (min-width: $widthMobile) {
  .carac-column-container {
    display: flex;
    justify-content: center;
    margin-left: unset;
    margin-top: 7.962962962962963vh;
    margin-bottom: unset;
  }
}

.carac-group {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: $widthMobile) {
  .carac-group {
    width: 29.114583333333336vw;
  }
}

.carac-column-black {
  text-align: left;
  font: normal normal 18px/22px Aileron-Regular;
  color: #282e49;
  vertical-align: top;
}

@media only screen and (min-width: $widthMobile) {
  .carac-column-black {
    /* UI Properties */
    text-align: left;
    font: normal normal 0.9375vw/2.0370370370370368vh Aileron-Regular;
    color: #282e49;
    margin-right: 1.5625vw;
  }
}

.carac-row-black {
  margin-bottom: 15px;
  width: 170px;
}

@media only screen and (min-width: $widthMobile) {
  .carac-row-black {
    margin-bottom: 2.222222222222222vh;
    width: unset;
    max-width: unset;
  }
}

.carac-column-grey-1 {
  text-align: left;
  font: normal normal 300 18px/22px Aileron-Regular;
  color: #83879c;
  opacity: 1;
  vertical-align: top;
}

@media only screen and (min-width: $widthMobile) {
  .carac-column-grey-1 {
    text-align: left;
    font: normal normal 300 0.9375vw/2.0370370370370368vh Aileron-Regular;
    color: #83879c;
    opacity: 1;
    width: 16vw;
  }
}

.carac-row-grey {

  width: 160px;
  margin-left: 10px;
}

@media only screen and (min-width: $widthMobile) {
  .carac-row-grey {
    margin-bottom: 2.222222222222222vh;
    width: unset;
    margin-left: 3vw;
  }
}
