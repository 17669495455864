@import '../../../components/common/variables';

.section-5-performances-afg {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.section-performances-afg-container-background {
  background-color: #f7f7fa;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-afg-container-background {
    background-color: #f7f7fa;
    height: calc(70vh + 14.72vh);
    display: flex;
    align-items: center;
  }
}

.section-performances-afg-container {
  margin-top: 54px;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-afg-container {
    margin-top: 8.333333333333332vh;
    margin-left: 21.666666666666668vw;
    margin-right: 14.583333333333334vw;
  }
}

.section-titre-performances-afg {
  text-align: left;
  font: normal normal normal 32px/42px Aileron-Regular;
  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-performances-afg {

    height: 7.592592592592592vh;
    font: normal normal normal 6.018518518518518vh Aileron-Regular;
  }
}

.performances-afg-titre-divider {
  width: 229px;
  border-bottom: 1px solid #282e49;
  margin-top: 11px;
  margin-bottom: 34px;
}

@media only screen and (min-width: $widthMobile) {
  .performances-afg-titre-divider {
    width: 20.729166666666668vw;
    margin-top: 4.722222222222222vh;
    margin-bottom: 4.0740740740740735vh;
  }
}

.performances-afg-sous-titre {
  text-align: left;
  font: normal normal normal 27px/34px Aileron-Regular;
  color: #c1a367;
}

@media only screen and (min-width: $widthMobile) {
  .performances-afg-sous-titre {
    height: 4.259259259259259vh;
    font: normal normal normal 1.8229166666666667vw/3.6111111111111107vh Aileron-Regular;
  }
}

.table-vue-globale {
  display: flex;
  overflow: scroll;
}

@media only screen and (min-width: $widthMobile) {
  .table-vue-globale {
    display: flex;
    overflow: unset;
    margin-bottom: unset;
  }
}

@media only screen and (min-width: $widthMobile) {
  .titres-group {
    height: 6.018518518518518vh;
  }
}

.titre-row-fonds {

  height: 50px;
  text-align: left;
  font: normal normal bold 13px/50px Aileron-Regular;


  padding-left: 10px;
  border-top: #9a9ba8 1px solid;
}

@media only screen and (min-width: $widthMobile) {
  .titre-row-fonds {

    height: 6.018518518518518vh;
    text-align: left;
    font: normal normal bold 1.6666666666666665vh/6.018518518518518vh Aileron-Regular;
    
  }
}

.row-text-empty {
  height: 16px;
}

@media only screen and (min-width: $widthMobile) {
  .row-text-empty {
    width: 11.145833333333334vw;
    height: 1.6666666666666665vh;
  }
}

.titre-row-empty {
  height: 38px;
  margin: 2px 3px 0 3px;
}

@media only screen and (min-width: $widthMobile) {
  .titre-row-empty {
    width: 11.145833333333334vw;
    height: 6.018518518518518vh;
    margin: 0.18518518518518517vh 0.10416666666666667vw 0.18518518518518517vh 0.10416666666666667vw;
  }
}

.row-text-brute {
  height: 14px;
  text-align: center;
  font: normal normal 300 11px/14px Aileron-Regular;

}

@media only screen and (min-width: $widthMobile) {
  .row-text-brute {
    width: 25.729166666666668vw;
    height: 1.6666666666666665vh;
    font: normal normal 300 0.7291666666666667vw/1.6666666666666665vh Aileron-Regular;
  }
}

.row-text-annualisee {
  height: 14px;
  text-align: center;
  font: normal normal 300 11px/14px Aileron-Regular;

  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .row-text-annualisee {
    width: 369;
    height: 1.6666666666666665vh;
    text-align: center;
    font: normal normal 300 0.7291666666666667vw/1.6666666666666665vh Aileron-Regular;
  }
}

.head-grey-container {
  display: flex;
  border-top: #9a9ba8 1px solid;
}

.head-grey-container-1 {
  display: flex;

}

.head-el-grey {
  width: 90px;
  height: 38px;
  text-align: center;
  font: normal normal 300 13px/38px Aileron-Regular;
  color: #9a9ba8;
  margin: 2px 3px 2px 3px;
}

@media only screen and (min-width: $widthMobile) {
  .head-el-grey {
    width: 6.25vw;
    height: 6.018518518518518vh;
    text-align: center;
    font: normal normal 300 0.9375vw/6.018518518518518vh Aileron-Regular;
    opacity: 1;
    margin: 0.18518518518518517vh 0.10416666666666667vw 0.18518518518518517vh 0.10416666666666667vw;
  }
}

.head-el {
  width: 90px;
  height: 50px;
  text-align: center;
  font: normal normal 500 13px/50px Aileron-Regular;
  opacity: 1;
  margin: 2px 3px 2px 3px;
}

@media only screen and (min-width: $widthMobile) {
  .head-el {
    width: 6.25vw;
    height: 6.018518518518518vh;
    text-align: center;
    font: normal normal 500 0.9375vw/6.018518518518518vh Aileron-Regular;
    opacity: 1;
    margin: 0.18518518518518517vh 0.10416666666666667vw 0.18518518518518517vh 0.10416666666666667vw;
  }
}

.head-el-blue {
  width: 90px;
  height: 37px;
  text-align: center;
  font: normal normal 300 13px/37px Aileron-Regular;
  color: #9a9ba8;
  margin: 2px 3px 3px 3px;
}

@media only screen and (min-width: $widthMobile) {
  .head-el-blue {
    width: 6.25vw;
    height: 6.018518518518518vh;
    text-align: center;
    font: normal normal 300 0.9375vw/6.018518518518518vh Aileron-Regular;

    margin: 0.18518518518518517vh 0.10416666666666667vw 0.18518518518518517vh 0.10416666666666667vw;
  }
}
