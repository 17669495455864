@import '../../common/variables';

.section-gamme {
  width: 100vw;
  background: #f7f7fa;
}

@media only screen and (min-width: $widthMobile) {
  .section-gamme {
    width: 100vw;
    background: #f7f7fa;
  }
}

.section-gamme-container {
  width: 100vw;
  background: #f7f7fa;
}

@media only screen and (min-width: $widthMobile) and (max-height: 800px) {
  .section-gamme-container {
    scale: 75%;
  }
}

@media only screen and (min-width: $widthMobile) {
  .section-gamme-container {
    width: 100vw;
    display: flex;
    background: #f7f7fa;
    height: calc(100vh + 14.7222vh);
    justify-content: center;
    align-items: center;
  }
}

.section-gamme-side-left {
  padding: 44px 30px 0 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-gamme-side-left {
    padding: 0;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media only screen and (min-width: $widthMobile) {
  .section-gamme-side-left-container {
    margin-right: 93px;
  }
}

.title-gamme {
  width: fit-content;
  text-align: left;
  font: normal normal normal calc(32px + 1.5625vw) Aileron-Regular;
  color: #282e49;
  border-bottom: 1px solid #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .title-gamme {
    width: 20.729166666666668vw;
    /* UI Properties */
    text-align: left;
    color: #282e49;
    border-bottom: 1px solid #282e49;
  }
}

.side-left-paragraph {
  margin: 35px 0 20px 0;
  font: normal normal 300 calc(15px + 0.2083vw) Aileron-Regular;
  color: #282e49;
  text-align: justify;
}

@media only screen and (min-width: $widthMobile) {
  .side-left-paragraph {
    margin: 5vh 0 2.8125vw 0;
    width: 18.90625vw;
    line-height: 30px;
  }
}

.offres-list-element-container-activated {
  width: 23.958333333333336vw;
  background-color: #282e49;
  line-height: 4.907407407407407vh;
  text-align: left;
  box-shadow: -0.9259259259259258vh 0px 0px 0px #c1a367;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: -0.2604166666666667vw;
}

.offres-list-element-text-activated {
  margin-bottom: 200px;
  font: normal normal normal calc(13px + 0.26041667vw) Aileron-Regular;
  padding-left: 0.2604166666666667vw;
  white-space: nowrap;

}

.offres-list-element-container {
  width: 23.958333333333336vw;
  line-height: 4.907407407407407vh;
  text-align: left;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: -0.2604166666666667vw;
}

.offres-list-element-text {
  font: normal normal normal calc(13px + 0.26041667vw) Aileron-Regular;
  color: #282e49;
  padding-left: 0.2604166666666667vw;
  white-space: nowrap;
}

.section-gamme-side-right {
  margin-top: 30px;
  margin-left: 30px;
}

.section-gamme-side-right-container {
  min-height: 700px;
}

@media only screen and (min-width: $widthMobile) {
  .section-gamme-side-right-container {
    height: 62.7222vh;
    min-height: 630px;
  }
}

@media only screen and (min-width: $widthMobile) {
  .section-gamme-side-right {
    margin-top: 0;
    margin-left: 0;
    width: 50vw;
  }
}
