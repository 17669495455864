@import '../../../components/common/variables';

.section-5-profil-gestion {
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
  background-color: #282e49;
}

.section-profil-gestion-container-background {
  height: unset;
}

@media only screen and (min-width: $widthMobile) {
  .section-profil-gestion-container-background {
    height: calc(100vh + 14.72vh);
    display: flex;
    align-items: center;
  }
}

.section-profil-gestion-container {
  margin-top: 76px;
  margin-left: 30px;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-profil-gestion-container {
    margin-top: 7.222222222222221vh;
    margin-left: 21.666666666666668vw;
    margin-right: 14.583333333333334vw;
  }
}

.section-titre-profil-gestion-text {
  /* UI Properties */
  text-align: left;
  font: normal normal normal 52px/66px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-profil-gestion-text {
    width: 30.104166666666668vw;
    height: 7.592592592592592vh;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #ffffff;
    opacity: 1;
  }
}

.strategie-benchmark-container {
  display: unset;
}

@media only screen and (min-width: $widthMobile) {
  .strategie-benchmark-container {
    display: flex;
  }
}

.profil-gestion-titre-divider {
  width: 229px;
  border-bottom: 1px solid #f7f7fa;
  margin-top: 25px;
}

@media only screen and (min-width: $widthMobile) {
  .profil-gestion-titre-divider {
    width: 20.729166666666668vw;
    margin-top: 4.722222222222222vh;
  }
}

.ticket-strategie {

  text-align: left;
  font: normal normal normal 20px/47px Aileron-Regular;
  color: #ffffff;
  background-color: #c1a367;
  padding-left: 16px;
  margin-top: 40px;
  margin-bottom: 29px;
}

@media only screen and (min-width: $widthMobile) {
  .ticket-strategie {
    width: unset;
    text-align: left;
    padding-right: 16px;
    font: normal normal normal 1.8229166666666667vw/4.907407407407407vh Aileron-Regular;
    padding-left: 0.8333333333333334vw;
    padding-right: 0.8333333333333334vw;
    margin-top: 10.37037037037037vh;
    margin-bottom: 3.2407407407407405vh;
  }
}

.paragraph-under-ticket-strategie {
  text-align: left;
  width: 80%;
  font: normal normal 300 21px/24.98px Aileron-Regular;
  color: #ffffff;
  margin-bottom: 15px;
  line-height: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .paragraph-under-ticket-strategie {
    width: 21.875vw;
    text-align: left;
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    color: #ffffff;
    margin-bottom: 0.9259259259259258vh;
    line-height: 30px;
  }
}

.list-opportunite-el {
  text-align: left;
  font: normal normal 21px/20px Aileron-Regular;
  color: #ffffff;
  margin-bottom: 10px;
}

@media only screen and (min-width: $widthMobile) {
  .list-opportunite-el {
    text-align: left;
    font: normal normal 1.09375vw/2.314814814814815vh Aileron-Regular;
    color: #ffffff;
    margin-bottom: 0.9259259259259258vh;
    margin-top: 20px;
  }
}

.profil-gestion-titre-divider-2 {
  width: 20.729166666666668vw;
  border-bottom: 1px solid #f7f7fa;
  margin-top: 18vh;
}

.side-benchmark {
  text-align: left;
  margin-top: 45px;
}

@media only screen and (min-width: $widthMobile) {
  .side-benchmark {
    text-align: left;
    margin-top: 6.5740740740740735vh;
    margin-left: 8.958333333333334vw;
  }
}

.benchmark-titre {
  text-align: left;
  font: normal normal normal 36px/47px Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .benchmark-titre {
    width: 25.260416666666668vw;
    height: 4.351851851851851vh;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 1.875vw/3.6111111111111107vh Aileron-Regular;
    color: #ffffff;
  }
}

.benchmark-divider {
  width: 103px;
  /* UI Properties */
  border-top: 2px solid #c1a367;
  margin-bottom: 13px;
}

@media only screen and (min-width: $widthMobile) {
  .benchmark-divider {
    width: 5.364583333333334vw;
    /* UI Properties */
    border-top: 0.37037037037037035vh solid #c1a367;
    margin-bottom: 1.6666666666666665vh;
  }
}

.sub-benchmark-titre {
  text-align: left;
  font: normal normal 300 21px/25px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 28px;
  width: 95%;
  line-height: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .sub-benchmark-titre {
    width: 25.260416666666668vw;
    height: 6.944444444444444vh;
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    margin-bottom: unset;
    line-height: 25px;
    margin-bottom: 20px;
  }
}

.div-lottie-profil-gestion {
  display: flex;
  flex-direction: column;
  position: absolute;
}

@media only screen and (min-width: $widthMobile) {
  .div-lottie-profil-gestion {
    display: flex;
    flex-direction: column;
    transform: translate(0, -17.5vh);
    position: unset;
  }
}
