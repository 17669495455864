@import '../../../components/common/variables';

.section-8-documentation {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.section-documentation-container-background {
  background-color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .section-documentation-container-background {
    height: calc(65vh + 14.7222vh);
    display: flex;
    align-items: center;
  }
}

.section-documentation-container {
  margin-top: 39px;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-documentation-container {
    margin-top: unset;
    margin-left: 21.666666666666668vw;
    margin-right: 14.583333333333334vw;
  }
}

.section-titre-documentation {
  /* UI Properties */
  text-align: left;
  font: normal normal normal 38px/67px Aileron-Regular;

  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-documentation {
    width: 30.104166666666668vw;
    height: 7.592592592592592vh;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  }
}

.documentation-titre-divider {
  width: 229px;
  border-bottom: 1px solid #f7f7fa;
  margin-top: 20px;
  margin-bottom: 33px;
}

@media only screen and (min-width: $widthMobile) {
  .documentation-titre-divider {
    width: 20.729166666666668vw;
    margin-top: 4.722222222222222vh;
    margin-bottom: 4.0740740740740735vh;
  }
}

.documentation-sides-container {
  display: unset;
}

@media only screen and (min-width: $widthMobile) {
  .documentation-sides-container {
    display: flex;
  }
}

.documentation-side-titre {
  text-align: left;
  font: normal normal normal 20px/39px Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .documentation-side-titre {
    font: normal normal normal 1.875vw/3.6111111111111107vh Aileron-Regular;
  }
}

.documentation-side-divider {
  width: 103px;
  border-bottom: 2px solid #c1a367;
  margin-top: 10px;
  margin-bottom: 13px;
}

@media only screen and (min-width: $widthMobile) {
  .documentation-side-divider {
    width: 5.364583333333334vw;
    margin-top: 1.8518518518518516vh;
    margin-bottom: 1.6666666666666665vh;
  }
}

.documentation-side-text {
  text-align: left;
  font: normal normal 300 17px/20px Aileron-Regular;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .documentation-side-text {
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    margin-bottom: 5.37037037037037vh;
    margin-right: 0;
    width: 23.072916666666668vw;
    height: 4.62962962962963vh;
  }
}

@media only screen and (min-width: $widthMobile) {
  .documentation-side-2 {
    margin-left: 5.260416666666667vw;
  }
}

.documentation-side-telecharger-container {
  display: flex;
  text-align: left;
  font: normal normal 600 17px/20px Aileron-Regular;

  color: #f7f7fa;
  margin-bottom: 50px;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .documentation-side-telecharger-container {
    font: normal normal 600 0.78125vw/3.7037037037037033vh Aileron-Regular;
    margin-bottom: 0;
  }
}

.documentation-telecharger-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
