@import '../../components/common/variables';

.section-kpis {
  background: #282e49 0% 0% no-repeat padding-box;
  padding-bottom: 78px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .section-kpis {
    height: 100vh;
    padding-bottom: 0;
  }
}
.section-kpis-container {
  margin-bottom: 60px;
}
@media only screen and (min-width: $widthMobile) {
  .section-kpis-container {
    margin-bottom: unset;
    height: 100vh;
  }
}

@media only screen and (min-width: $widthMobile) and (max-width: 1600px) {
  .section-kpis-container {
    height: 100vh;
    scale: 80%;
  }
}

.title-capitale {
  font: normal normal normal calc(25px + 1.17096018735363vw) Aileron-Regular;
  margin: 80px 0 0 30px;
  text-align: left;
  color: #e4e5ea;
}

@media only screen and (min-width: $widthMobile) {
  .title-capitale {
    margin: 0;
    font-size: calc(40px + 2.604166666666667vw);
    line-height: 95px;
    margin-right: 5vw;
  }
}

.chiffre-5 {
  font: normal normal normal calc(52px + 4.322916666666667vw) Aileron-Regular;
}

.chiffre-5-sub-g {
  display: flex;
  white-space: nowrap;
}

@media only screen and (min-width: $widthMobile) {
  .chiffre-5-sub-g {
    display: block;
  }
}

.rounded-capitale {
  border: 1px solid #e4e5ea;
  width: 229px;
  position: relative;
  margin: 2.222222222222222vh 0 30px 0;
}

@media only screen and (min-width: $widthMobile) {
  .rounded-capitale {
    border: 1px solid #e4e5ea;
    width: 19.791666666666668vw;
    margin: 11.481481481481481vh 0 0 0;
  }
}

@media only screen and (min-width: $widthMobile) {
  .side-chiffres-left {
    display: flex;
    flex-direction: column;
    width: 50vw;
    align-items: flex-end;
    justify-content: center;
    height: calc(100vh + 14.7222vh);
  }
}

@media only screen and (min-width: $widthMobile) {
  .side-chiffres {
    height: calc(100vh + 14.72vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.stats-row {
  position: relative;
  margin-bottom: 29px;
  display: flex;
}

@media only screen and (min-width: $widthMobile) {
  .stats-row {
    display: flex;
  }
}

.last-stats-row {
  display: flex;
  margin-bottom: 0px;
}

@media only screen and (min-width: $widthMobile) {
  .stats-row {
    margin-bottom: 4.62962962962963vh;
  }
}

.stat-chiffre {
  text-align: left;
  font: normal normal normal calc(48px + 3.125vw)  Aileron-Regular;
  color: #e4e5ea;
  margin-left: 30px;
  height: 120px;
  width: 175px;
  padding-right: 50px;
}

@media only screen and (min-width: $widthMobile) {
  .stat-chiffre {
    width: 286px;
    min-width: 180px;
    padding-right: 30px;
    height: 100%;
  }
}

.stat-mrd {
  font: normal normal normal 15px / 8.796296296296296vh Aileron-Regular;
}

@media only screen and (min-width: $widthMobile) {
  .stat-mrd {
    font: normal normal normal calc(20px + 0.78125vw) / 8.796296296296296vh Aileron-Regular;
  }
}

.stat-definition {
  width: calc(2.604166666666667vw + 170px);
}

@media only screen and (min-width: $widthMobile) {
  .stat-definition {
    margin-left: 0.8333333333333334vw;
    position: relative;
  }
}

.stat-definition-title {
  text-align: left;
  font: normal normal normal calc(20px + 0.46875vw) Aileron-Regular;
  color: #c1a367;
}

.stat-definition-body {
  margin-top: 1.6666666666666665vh;
  text-align: left;
  font: normal normal 300 calc(17px + 0.2vw) Aileron-Regular;
  color: #ffffff;
  min-width: 130px;
}
