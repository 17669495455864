.section1 {
  height: 110vh;
  width: 100vw;
  background-color: black;
}

.section2 {
  height: 100vh;
  width: 100vw;
  background-color: rgb(210, 12, 12);
}

.section3 {
  height: 100vh;
  width: 100vw;
  background-color: rgb(62, 208, 162);
}

.section4 {
  height: 100vh;
  width: 100vw;
  background-color: rgb(28, 40, 214);
}
