@import '../../common/variables';

.section-performances {
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances {
    height: calc(100vh + 14.7222vh);
  }
}

.section-performances-container {
  position: relative;
  margin-top: 45px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-container {
    margin-top: 3vh;
    width: 41.614583333333336vw;
  }
}

.section-performances-title {
  height: 59px;
  width: 100%;
  font: normal normal bold 17px Aileron-Regular;
  color: #ffffff;
  background-color: #c1a367;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-title {
    width: 41.614583333333336vw;
    font: normal normal bold 1.5104166666666667vw/5.462962962962963vh Aileron-Regular;
    height: unset;
  }
}

.section-performances-logo-back {
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-logo-back {
    margin-left: 1.3020833333333335vw;
  }
}

.section-performances-logo-front {
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-logo-front {
    margin-right: 1.3020833333333335vw;
  }
}

.section-performances-sub-title-1 {
  height: 46px;
  text-align: center;
  font: normal normal bold 22px Aileron-Regular;
  color: #282d48;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-sub-title-1 {
    height: unset;
    display: unset;
    font: normal normal bold 1.4583333333333335vw/6.111111111111111vh Aileron-Regular;
  }
}

.section-performances-sub-title-2 {
  height: 42px;
  width: 100%;
  text-align: center;
  font: normal normal bold 17px Aileron-Regular;
  color: #ffffff;
  background-color: #52576d;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-sub-title-2 {
    font: normal normal bold 0.9895833333333334vw/3.888888888888889vh Aileron-Regular;
    height: unset;
    display: unset;
  }
}

.section-performances-titre-durees {
  font: normal normal 500 21px/25px Aileron-Regular;
  width: 272px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 16px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-titre-durees {
    width: 100%;
    font: normal normal 500 0.9895833333333334vw/2.1296296296296293vh Aileron-Regular;
    margin-top: 4.814814814814815vh;
  }
}

.section-performances-logo-durees-container {
  width: 100%;
  margin-top: 12px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-logo-durees-container {
    width: 100%;
    margin-top: 2.4074074074074074vh;
  }
}

.section-performances-logo-durees-container-bis {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-logo-durees-container-bis {
    display: flex;
    justify-content: space-between;
    margin: 0 4.479166666666667vw;
  }
}

.legende-risque-container {
  font: normal normal normal 16px/19px Aileron-Regular;
  display: flex;
  justify-content: space-between;
  margin: 15px 30px 0 30px;
  width: 100%;
}

@media only screen and (min-width: $widthMobile) {
  .legende-risque-container {
    font: normal normal normal 0.9375vw/2.0370370370370368vh Aileron-Regular;
    margin: 2.314814814814815vh 4.479166666666667vw 0 4.479166666666667vw;
  }
}

.legende-risque-container-text-left {
  margin-left: 45px;
}

@media only screen and (min-width: $widthMobile) {
  .legende-risque-container-text-left {
    margin-left: 4.479166666666667vw;
  }
}

.legende-risque-container-text-right {
  margin-right: 45px;
}

@media only screen and (min-width: $widthMobile) {
  .legende-risque-container-text-right {
    margin-right: 4.479166666666667vw;
  }
}

.text-overflow-center {
  text-align: center;
}

.section-performances-titre-couleurs {
  font: normal normal 500 21px/25px Aileron-Regular;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-titre-couleurs {
    font: normal normal 500 0.9895833333333334vw/2.1296296296296293vh Aileron-Regular;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 2.5vh;
  }
}

.section-performances-titre-couleurs-mot {
  font: normal normal 500 14px/16px Aileron-Regular;
  margin-top: 10px;
  align-self: flex-start;
  margin-left: 45px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-titre-couleurs-mot {
    font: normal normal 500 0.7vw/2.1296296296296293vh Aileron-Regular;
    margin-top: 2.5vh;
    margin-left: unset;
  }
}

.position-text-container {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .position-text-container {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.section-performances-logo-durees {
  width: 50px;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-logo-durees {
    width: 60px;
    display: flex;
    justify-content: center;
  }
}

.section-performances-moteur-performances {
  align-self: flex-start;
  margin-left: 45px;
  margin-top: 5px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-moteur-performances {
    width: 41.614583333333336vw;
    margin-top: 1vh;
    align-self: unset;
    margin-left: unset;
  }
}

.moteur-performance-element {
  display: unset;
  text-align: left;
}

@media only screen and (min-width: $widthMobile) {
  .moteur-performance-element {
    display: flex;
    text-align: center;
  }
}

.moteur-performance-element-key {
  text-align: left;
  font: normal normal 300 12px/14px Aileron-Regular;
}

@media only screen and (min-width: $widthMobile) {
  .moteur-performance-element-key {
    width: 50%;
    margin-bottom: 1.2962962962962963vh;
    margin-right: 0.9375vw;
    font: normal normal 300 0.65vw/2.0370370370370368vh Aileron-Regular;
  }
}

.moteur-performance-element-values-container {
  width: 100%;
  text-align: left;
  margin-left: 0;
  margin-bottom: 5px;
}

@media only screen and (min-width: $widthMobile) {
  .moteur-performance-element-values-container {
    margin-left: 0.9375vw;
    margin-bottom: 0;
  }
}

.moteur-performance-element-values {
  margin-top: 6px;
  width: 290px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: $widthMobile) {
  .moteur-performance-element-values {
    margin-top: 0.65vh;
    width: 15.989583333333334vw;
    display: flex;
    justify-content: space-between;
  }
}

.performance-trait-rempli {
  width: 68px;
  height: 5px;
  background-color: #282e49;
  border-radius: 5px;
}

.performance-trait-vide {
  width: 68px;
  height: 5px;
  border-radius: 0.7407407407407407vh;
  border: solid 0.09259259259259259vh #282e49;
  box-sizing: border-box;
}

.section-performances-titre-remarque-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  align-self: flex-start;
  margin-left: 45px;
  margin-top: 10px;
  margin-right: 40px;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-titre-remarque-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: unset;
    margin-top: 5px;
    margin-left: unset;
  }
}

.section-performances-titre-remarque {
  text-align: left;
  font: normal normal normal 9px/14px Aileron-Regular;
  color: #85868b;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-titre-remarque {
    font: normal normal normal 0.493vw/1.3888888888888888vh Aileron-Regular;
  }
}

.section-performances-titre-remarque-values {
  width: 219px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 0;
  align-self: flex-start;
}

@media only screen and (min-width: $widthMobile) {
  .section-performances-titre-remarque-values {
    width: 219px;
    display: flex;
    justify-content: space-between;
    margin-top: 0.3029629629629629vh;
    margin-left: 0.9375vw;
  }
}

.performance-trait-rempli-remarque {
  width: 48px;
  background-color: #282e49;
  height: 5px;
  border-radius: 0.27777777777777773vh;
}
