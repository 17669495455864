.section-nous-contacter {
  width: 100vw;
  display: flex;
  background: #f7f7fa 0% 0%;
  align-items: center;
  justify-content: center;
}

.section-nous-contacter-container {
  background: #ffffff;
  width: 100%;
  margin-bottom: 11.388888888888888vh;
}

.section-nous-contacter-container-bis {
  position: relative;
  margin-top: 5.833333333333333vh;
  margin-left: 7.760416666666667vw;
  margin-bottom: 6.481481481481481vh;
}

.nous-contacter-title {
  /* UI Properties */
  text-align: left;
  font: normal normal normal calc(10px + 3vw)/6.018518518518518vh Aileron-Regular;

  color: #282e49;
  opacity: 1;
}

.divider-nous-contacter {
  width: 7.239583333333334vw;
  border-bottom: 1px solid #282e49;
}

.nous-contacter-menu {
  width: 56.71875vw;
  max-width: 350px;
  text-align: left;
  margin-bottom: 3.0555555555555554vh;
}

.field-nom {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6866669d;
  border-radius: 3px;
  margin-top: 3.0555555555555554vh;
  padding-left: 0.78125vw;
  padding-right: 1.3888888888888888vh;
  width: 56.71875vw;
  color: #6866669d;
}


.field-nous-contacter {
  // width: 56.71875vw;
  width: 90% !important;
  margin-bottom: 1.4814814814814814vh;
}

.field-nous-contacter-commentaire {
  // width: 56.71875vw;
  width: 90%;
  margin-bottom: 1.4814814814814814vh;
  text-align: left;
}
.label-input{
  font-size:12px;
}

.field-nous-contacter-envoyer {
  // width: 56.71875vw;
  width: 90%;
  text-align: right;
}
