@import '../components/common/variables';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (min-width: $widthMobile) {
  .video-super-container {
    height: 100vh;
    overflow: hidden;
    margin-top: unset;    
  }
}

.video-container {
  pointer-events: none;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

@media only screen and (min-width: $widthMobile) {
  .video-container {
    pointer-events: none;
    z-index: 0;
    overflow: hidden;
    position: relative;
    //padding-top: 56.26%; /* 720 / 1280 = 0.5625 */
    height: unset;
  }
}

.scroll-down-img-container {
  :hover {
    scale: 1.1;
    cursor: pointer;
  }
}

.one-page-container {
  overflow-x: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .one-page-container {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    height: 100vh;
  }
}

.black-hide-author {
  background-color: black;
  position: absolute;
  height: 54px;
  width: 250px;
}

.black-hide-copier {
  background-color: black;
  position: absolute;
  height: 60px;
  width: 80px;
  right: 30px;
}

.black-hide-logo {
  background-color: black;
  position: absolute;
  height: 54px;
  width: 115px;
  right: 20px;
  bottom: 0;
}

@media only screen and (min-width: $widthMobile) and (min-height: 710px) {
  .sections-container {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    margin: 0 auto;
  }
}

@media only screen and (min-width: $widthMobile) {
  section {
    height: 100vh;
    scroll-snap-align: start;
    overflow: hidden;
  }
}

@media only screen and (min-width: $widthMobile) {
  section {
    height: 100vh;
  }
}

@media only screen and (min-width: $widthMobile) {
  section.bis {
    height: calc(50vh + 14.72vh);
    scroll-snap-align: start;
  }
}

section.bis2 {
  height: calc(35vh + 14.72vh);
  scroll-snap-align: start;
}

section.bis3 {
  height: 75vh;
  scroll-snap-align: start;
}

section.bis5 {
  height: unset;
  scroll-snap-align: start;
}

@media only screen and (min-width: $widthMobile) {
  section.bis5 {
    height: calc(65vh + 14.72vh);
  }
}

section.bis4 {
  height: 25vh;
  scroll-snap-align: start;
}

.videoTag {
  min-width: 150vw;
  height: 120vh;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: $widthMobile) {
  .hidden-scrollbar-y {
    overflow-y: hidden;
  }
}

.transition-div {
  position: relative;
  top: 50vh;
}

.counter-app-bar {
  height: 14.7222222vh;
}
