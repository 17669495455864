@import '../../common/variables';

.section-question {
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  background-color: #282e49;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
  justify-content: center;
}

@media only screen and (min-width: $widthMobile) {
  .section-question {
    height: calc(36vh + 7vh);
  }
}

.section-question-text {
  position: relative;
  margin-top: 60px;
  margin-left: 30px;
  margin-bottom: 60px;
  text-align: left;
  font: normal normal normal 42px/49px Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .section-question-text {
    margin-top: 13vh;
    margin-left: 21.666666666666668vw;
    margin-bottom: unset;
    width: 23vw;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  }
}

.section-presentation-performances {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $widthMobile) {
  .section-presentation-performances {
    height: calc(65vh - 10vh);
    display: flex;
    flex-direction: column;
  }
}

.section-presentation-performances-container {
  position: relative;
  margin-top: 31px;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-presentation-performances-container {
    position: relative;
    margin-top: 5.7407407407407405vh;
    margin-left: 21.666666666666668vw;
  }
}

.section-presentation-performances-text {
  text-align: left;
  font: normal normal normal 32px/35px Aileron-Regular;
  color: #282e49;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-presentation-performances-text {
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    margin-right: unset;
  }
}

.divider-presentation-performances {
  position: relative;
  margin-top: 18px;
  margin-bottom: 33px;
  width: 229px;
  border-bottom: 1px solid #c1a367;
}

@media only screen and (min-width: $widthMobile) {
  .divider-presentation-performances {
    margin-top: 3.888888888888889vh;
    margin-bottom: 4.907407407407407vh;
    width: 20.729166666666668vw;
  }
}

.presentation-performances-paragraph {
  position: relative;
  font: normal normal 300 18px/21px Aileron-Regular;
  margin-right: 30px;
  color: #282e49;
  margin-bottom: 27px;
  text-align: justify;
  line-height: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .presentation-performances-paragraph {
    width: 56.66666666666667vw;
    margin-right: 0;
    font: normal normal 300 1.09375vw/2.5925925925925926vh Aileron-Regular;
    margin-bottom: 0;
    line-height: 25px;
    
  }
}

.div-lottie {
  display: flex;
  flex-direction: column;
  transform: translate(0, 30px);
}

@media only screen and (min-width: $widthMobile) {
  .div-lottie {
    transform: translate(0, -25.5vh);
  }
}
