@import '../../../components/common/variables';

.section-7-evolution-performances {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.section-evolution-performances-container-background {
  background-color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .section-evolution-performances-container-background {
    height: calc(100vh + 14.7222vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.section-evolution-performances-container {
  margin-top: 39px;
  margin-left: 30px;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-evolution-performances-container {
    margin-top: unset;
    margin-left: unset;
    margin-right: unset;
  }
}

.section-titre-evolution-performances {
  text-align: left;
  font: normal normal normal 27px/39px Aileron-Regular;

  color: #c1a367;
  opacity: 1;
  margin-bottom: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .section-titre-evolution-performances {
    height: 4.259259259259259vh;
    font: normal normal normal 1.8229166666666667vw/4.259259259259259vh Aileron-Regular;
    margin-bottom: 7.314814814814814vh;
  }
}

.evolution-performances-radio-container {
  display: flex;
}

.radio-text {
  margin-right: 5.625vw;
  color: white;
}

.radio-annee {
  margin-right: 2.5vw;
  color: white;
}

