@import '../../common/variables';

.section-nos-fonds {
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  background-color: #282e49;
  height: 241px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .section-nos-fonds {
    height: 47.31481481481481vh;
    background-color: #282e49;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;
  }
}

.section-nos-fonds-text {
  position: relative;
  margin-top: 85px;
  margin-left: 30px;
  text-align: left;
  font: normal normal normal 42px/55px Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .section-nos-fonds-text {
    position: relative;
    margin-top: 19vh;
    margin-left: 21.666666666666668vw;
    width: 23vw;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #ffffff;
  }
}

.div-lottie-nos-fonds {
  display: flex;
  flex-direction: column;
  transform: translate(0, -15.5vh);
}
