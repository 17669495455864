@import '../../components/common/variables';

.vl-performances-titre-principal {
  height: 200px;
  width: 100vw;
  background-color: #282e49;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: $widthMobile) {
  .vl-performances-titre-principal {
    height: 50vh;
    width: 100vw;
  }
}

.vl-performances-titre-principal-text {
  margin-top: 70px;
  margin-left: 30px;
  text-align: left;
  font: normal normal normal 38px/49px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
  position: absolute;
}

@media only screen and (min-width: $widthMobile) {
  .vl-performances-titre-principal-text {
    margin-top: 22vh;
    margin-left: 21.666666666666668vw;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
    color: #ffffff;
    opacity: 1;
    position: absolute;
  }
}

.vl-performances-titre-2-text {
  margin-top: 30px;
  margin-left: 30px;
  text-align: left;
  font: normal normal normal 20px/30px Aileron-Regular;
  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .vl-performances-titre-2-text {
    margin-top: 13.333333333333332vh;
    margin-left: 21.666666666666668vw;
    text-align: left;
    font: normal normal normal 3.229166666666667vw/6.018518518518518vh Aileron-Regular;
  }
}

.divider-vl-performances {
  margin-top: 30px;
  width: 200px;
  border-bottom: 1px solid #282e49;
  margin-left: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .divider-vl-performances {
    margin-top: 3.7037037037037033vh;
    width: 20.729166666666668vw;
    border-bottom: 1px solid #282e49;
    margin-left: 21.666666666666668vw;
  }
}

.vl-performances-paragraph {
  /* UI Properties */
  margin-left: 30px;
  text-align: left;
  font: normal normal 300 14px/20px Aileron-Regular;
  color: #282e49;
  margin-top: 30px;
  margin-right: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .vl-performances-paragraph {
    /* UI Properties */
    margin-left: 21.666666666666668vw;
    text-align: left;
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    color: #282e49;
    width: 56.66666666666667vw;
    margin-top: 3.7037037037037033vh;
    margin-right: unset;
  }
}

.vl-performances-filtre-container-background {
  background-color: #f7f7fa;
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
}

.vl-performances-filtre-container {
  margin: 0 30px 0 30px;
}
.filter-periode-calcul {
  display: flex;
  margin-bottom: 30px;
}

@media only screen and (min-width: $widthMobile) {
  .filter-periode-calcul {
    height: 7.4074074074074066vh;
    display: flex;
  }
}

.vl-performances-menu-deroulant {
  width: 200px;
  height: 4.722222222222222vh;
}

@media only screen and (min-width: $widthMobile) {
  .vl-performances-menu-deroulant {
    width: 17.65625vw;
    height: 40px;
  }
}

.vl-performances-label-filtre {
  /* UI Properties */
  text-align: left;
  font: normal normal 14px/20px Aileron-Regular;
  color: #282e49;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: $widthMobile) {
  .vl-performances-label-filtre {
    width: 24.270833333333336vw;
    height: 4.722222222222222vh;
    /* UI Properties */
    text-align: left;
    font: normal normal 1.09vw/4.722222222222222vh Aileron-Regular;
    margin-bottom: 10px;
  }
}

.vl-performances-button-filtrer {
  text-align: left;
  margin-left: 3.7037037037037033vh;
}

.vl-performances-table-container-background {
  display: flex;
  align-items: center;
  justify-content: left;
  overflow-y: auto;
  margin-left: 10px;
}

@media only screen and (min-width: $widthMobile) {
  .vl-performances-table-container-background {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: unset;
  }
}

.vl-performances-table-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.row-header {
  display: flex;
  justify-content: space-between;
}

.vl-performances-table-divider {
  /* UI Properties */
  border-bottom: 1px solid #e4e5ea;
  opacity: 1;
  margin-bottom: 1.8518518518518516vh;
}

.cell-header {
  width: 193px;
  height: 51px;
  /* UI Properties */
  border: 0.20000000298023224px solid #282e49;
  border-radius: 3px;
  text-align: center;
  font: normal normal normal 12px Aileron-Regular;
  color: #282e49;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.cell-header-2 {
  width: 193px;
  height: 51px;
  /* UI Properties */
  border: 0.2px solid #282e49;
  border-radius: 3px;
  text-align: center;
  font: normal normal normal 12px Aileron-Regular;
  color: #282e49;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.row-data {
  display: flex;

  justify-content: space-between;
  border-bottom: 1px solid #e4e5ea;
}

.cell-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 193px;
  text-align: left;
  font: normal normal normal 13px Aileron-Regular;
  // color: #86878c;
  color: #282c34;
  
  text-transform: uppercase;
  margin: 0 5px;
}

.cell-data-chiffre {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 193px;
  text-align: center;
  font: normal normal normal 13px Aileron-Regular;
  // color: #86878c;
  color: #282c34;
  text-transform: uppercase;
  margin: 0 5px;
}

.cell-data-2 {
  width: 193px;
  text-align: center;
  font: normal normal normal 13px/72px Aileron-Regular;
  // color: #86878c;
  text-transform: uppercase;
  margin: 0 5px;
}

.cell-performance-text {
  text-align: right;
  font-weight: 500;
  color: #282c34;
}

.cell-performance-value {
  display: flex;
  justify-content: center;
}

.div-lottie-vl {
  display: flex;
  flex-direction: column;
  transform: translate(0, -4.5vh);
}
