@import '../../../components/common/variables';

.section-2-fcp-management {
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $widthMobile) {
  .section-2-fcp-management {
    width: 100vw;
    margin: 0 auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: $widthMobile) {
  .section-2-fcp-management {
    width: 100vw;
    margin: 0 auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }
}

.fcp-management-sides-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 23px 0 0 0;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-sides-container {
    margin: 0 0 0 0;
    flex-direction: row;
  }
}

.fcp-management-1-side-1 {
  background-color: #e4e5ea;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-1-side-1 {
    width: 23.958333333333336vw;
    height: 42.59259259259259vh;
  }
}

.fcp-management-1-side-2 {
  width: 350px;
  height: 350px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-1-side-2 {
    width: 23.958333333333336vw;
    height: 42.59259259259259vh;
    background-color: #ffffff;
  }
}

.fcp-management-side-1-titre {
  /* UI Properties */
  text-align: center;
  font: normal normal normal 30px/39px Aileron-Regular;
  color: #282e49;
  margin-top: 63px;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-side-1-titre {
    margin-top: 5vh;
    margin-bottom: 3.148148148148148vh;
    width: 22.1875vw;
    height: 10.092592592592592vh;
    font: normal normal normal 2vw/4.537037037037036vh Aileron-Regular;
  }
}

.fcp-management-side-1-sous-titre {
  text-align: center;
  font: normal normal 300 21px/25px Aileron-Regular;
  color: #282e49;
  margin-top: 43px;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-side-1-sous-titre {
    width: 22.1875vw;
    height: 2.314814814814815vh;
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    margin-top: 0;
    margin-bottom: 9.074074074074073vh;
  }
}

.fcp-management-side-1-grp-periodicite {
  display: flex;
  margin-top: 33px;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-side-1-grp-periodicite {
    margin-top: 0;
  }
}

.fcp-management-side-1-label-periodicite {
  text-align: center;
  font: normal normal normal 17px/37px Aileron-Regular;
  color: #282e49;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-side-1-label-periodicite {
    width: 8.28125vw;
    height: 5vh;
    font: normal normal normal 1.3541666666666667vw/5vh Aileron-Regular;
    color: #282e49;
  }
}

.fcp-management-side-1-value-periodicite {
  height: 37px;
  background: #86878c 0% 0% no-repeat padding-box;
  text-align: center;
  color: #ffffff;
  font: normal normal normal 17px/37px Aileron-Regular;
  margin-left: 5px;
  padding: 0 5px 0 5px;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-side-1-value-periodicite {
    height: 5vh;
    background: #86878c 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal normal 1.3541666666666667vw/5vh Aileron-Regular;
  }
}

.fcp-management-1-side-2-titre {
  /* UI Properties */
  text-align: center;
  font: normal normal normal 30px/39px Aileron-Regular;
  color: #282e49;
  margin-top: 90px;
  margin-bottom: 70px;
}

@media only screen and (min-width: $widthMobile) {
  .fcp-management-1-side-2-titre {
    margin-top: 7.5vh;
    margin-bottom: 3.3vh;
    width: 22.1875vw;
    height: 10.092592592592592vh;
    /* UI Properties */
    text-align: center;
    font: normal normal normal 2vw/4.537037037037036vh Aileron-Regular;
    color: #282e49;
  }
}

.profil-risque-container {
  width: 78%;
}

.profil-risque-titres-container {
  display: flex;
  justify-content: space-between;
}

.profil-titre-elmnt {
  font: normal normal 300 13px/16px Aileron-Regular;
}

@media only screen and (min-width: $widthMobile) {
  .profil-titre-elmnt {
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
  }
}

.profil-risque-divider {
  margin-top: 9px;
  border-bottom: 1px solid #282e49;
  margin-bottom: 8px;
}

@media only screen and (min-width: $widthMobile) {
  .profil-risque-divider {
    width: 18.5vw;
    margin-top: 1.5vh;
    margin-bottom: 1.2037037037037037vh;
  }
}

.profil-titre-legend {
  font: normal normal 300 9px/11px Aileron-Regular;
  color: #282e49;
  opacity: 1;
  text-align: left;
}

@media only screen and (min-width: $widthMobile) {
  .profil-titre-legend {
    width: 16.510416666666668vw;
    height: 1.6666666666666665vh;
    font: normal normal 300 0.7291666666666667vw/1.6666666666666665vh Aileron-Regular;
  }
}

.profil-risque-dividers-colored-container {
  display: flex;
  height: 30px;
  margin-top: 19px;
}

.profil-risque-divider-colored {
  width: 46px;
  margin-right: 0.5vw;
}

@media only screen and (min-width: $widthMobile) {
  .profil-risque-divider-colored {
    width: 3.4vw;
    margin-top: 2vh;
    margin-right: 0.5vw;
  }
}

.section-2-squars-1 {
  width: 100vw;
  margin: 0 auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.squars-1-sides-container {
  display: unset;
}

@media only screen and (min-width: $widthMobile) {
  .squars-1-sides-container {
    display: flex;
  }
}

.valeur-management-side-1 {
  width: 350px;
  height: 350px;
  background-color: #282e49;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .valeur-management-side-1 {
    width: 23.958333333333336vw;
    height: 42.59259259259259vh;
  }
}

.actif-side-2 {
  width: 350px;
  height: 350px;
  background-color: #c1a367;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: $widthMobile) {
  .actif-side-2 {
    width: 23.958333333333336vw;
    height: 42.59259259259259vh;
  }
}

.chiffre-valeur-side-1-titre {
  margin-top: 60px;
  margin-bottom: 30px;
  font: normal normal normal 33px/69px Aileron-Regular;
  color: #ffffff;
}

@media only screen and (min-width: $widthMobile) {
  .chiffre-valeur-side-1-titre {
    margin-top: 15vh;
    font: normal normal normal 2.34375vw/4.537037037037036vh Aileron-Regular;
    color: #ffffff;
  }
}

.devise-side-1-sous-titre {
  font: normal normal 300 21px/25px Aileron-Regular;
  color: #ffffff;
  // margin-bottom: 5px;
}

@media only screen and (min-width: $widthMobile) {
  .devise-side-1-sous-titre {
    font: normal normal 300 1.09375vw/2.314814814814815vh Aileron-Regular;
    // margin-bottom: 9.074074074074073vh;
  }
}

.valeur-liquid-1-sous-titre {
  text-align: center;
  font: normal normal normal 36px/47px Aileron-Regular;
  color: #ffffff;
  opacity: 1;
}

@media only screen and (min-width: $widthMobile) {
  .valeur-liquid-1-sous-titre {
    text-align: center;
    font: normal normal normal 1.875vw/3.6111111111111107vh Aileron-Regular;
    color: #ffffff;
    opacity: 1;
  }
}
